import React, { useContext, useEffect, useState } from 'react';


import './Form.scss';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import InlineInput from '../../../components/InlineInput/InlineInput';
import SIGNUP_IMAGE from '../../../assets/svg-bootstrap/sign-up.svg';
import Container from '../../../components/Layout/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BackendContext } from '../../../contexts/BackendContext';
import { useHistory } from 'react-router';


type FormTypes = {
  onSuccess: Function,
}

export default function Form({ onSuccess }: FormTypes) {
  const { registrationRequest, setRegistrationRequest, registrationResponse } = useContext(BackendContext)!;
  const [ passwordType, setPasswordType ] = useState<'text' | 'password'>('password');
  const [ confirmPasswordType, setConformPasswordType ] = useState<'text' | 'password'>('password');

  const history = useHistory();

  const setProperty = (propertyName: string, value: string) => {
    if(propertyName === "postCode") {
      value = value.toUpperCase();
      if( value.length+1 === registrationRequest?.postCode?.length && value.length === 3) {
        value = value.slice(0, 2);
      }
      if( value.length === 3 ) {
        value += " ";
      }
      if( value.length > 7 ) { return; }
    }
    setRegistrationRequest({
      ...registrationRequest,
      [propertyName]: value,
    });
  };

  const iconRightOnClick = () =>{
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }

  const confirmPasswordIconRightOnClick = () => {
    setConformPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
  }

  return (
    <div className="pdlRegistrationForm">
      <div className="sign-up">
        <Container>
          <Row>
          <Col sm={5} className="image" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <h2>Create your account</h2>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <img src={SIGNUP_IMAGE}/>
            </Col>
            <Col sm={1}/>
            <Col sm={6}>
              <InlineInput type="text" placeholder="Enter your individual or company name" label="Individual or Company Name" value={registrationRequest?.username} onChange={(username: string) => setProperty('username', username)}/>
              <div style={{marginTop: 20}}></div> {/** just for space */} 
              <InlineInput type="email" placeholder="Enter your email" label="Email" value={registrationRequest?.email} onChange={(email: string) => setProperty('email', email)}/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput 
                type="tel"
                placeholder="1******123" 
                label="Mobile Phone"
                value={registrationRequest?.phone}
                onChange={(phone: string) => setProperty('phone', phone)}
                onChangeCountryCode={(countryCode: string) => setRegistrationRequest({ ...registrationRequest, countryCode })}
                />
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type={passwordType} placeholder="Enter your password" label="Create a Password" iconRight={passwordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={iconRightOnClick}  value={registrationRequest?.password} onChange={(password: string) => setProperty('password', password)}/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type={confirmPasswordType} placeholder="Enter your password" label="Confirm Password" iconRight={confirmPasswordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={confirmPasswordIconRightOnClick} value={registrationRequest?.confirmPassword} onChange={(confirmPassword: string) => setProperty('confirmPassword', confirmPassword)}/>
              <div style={{marginTop: 20}}></div> {/** just for space */}
              <InlineInput type="text" placeholder="A1A B1B" label="Postal Code" value={registrationRequest?.postCode} onChange={(postCode: string) => setProperty('postCode', postCode)}/>
              <div style={{marginTop: 30}}></div> {/** just for space */}
              {registrationResponse?.apierror &&
                <>
                  <Text danger>{registrationResponse?.apierror?.message}</Text>
                  <div style={{marginTop: 30}}></div> {/** just for space */}
                </>
              }
              <Button label="Create account" block wider l onClick={onSuccess}/>
              <div style={{marginTop: 30}}></div> {/** just for space */}
              <div>
              <Text fontWeight={100} fontSize={15} b50={true}>
                *By click "Sign Up" button you will agree with our terms and
                conditions
              </Text>
              <div style={{marginTop: 30}}></div> {/** just for space */}
              <Text fontWeight={600} fontSize={20}>Already have an account ? <span style={{color:"#1877F2", cursor: "pointer"}} onClick={() => {history.push('/login')}}> Log In </span> </Text>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
