import React from 'react';
import Button from '../../components/Button/Button';
import InlineInput from '../../components/InlineInput/InlineInput';
import Text from '../../components/Text/Text';

import './BankingInfo.scss'


export default function BankingInfo(){
    return(
        <div className="pdlBankingInfo">
            <div className="pdlContainer">
                <div style={{marginTop: 73}}></div>
                <Text fontWeight={500} fontSize={30} lineHeight="45px">Your Deposit Information</Text>
                <div style={{marginTop: 24}}></div>
                <Text fontWeight={300} fontSize={16} lineHeight="24px">Your banking information is required to deposit your weekly earning into your account</Text>
                <div style={{marginTop: 32}}></div>
                <InlineInput type="text" placeholder="Enter your name" label="Account Holder Name"></InlineInput>
                <div style={{marginTop: 24}}></div>
                <InlineInput type="text" placeholder="Enter your transit number" label="Transit (Branch)"></InlineInput>
                <div style={{marginTop: 24}}></div>
                <InlineInput type="text" placeholder="Enter your institution number" label="Institution"></InlineInput>
                <div style={{marginTop: 24}}></div>
                <InlineInput type="text" placeholder="Enter your amount" label="Account"></InlineInput>
                <div style={{marginTop: 56}}></div>
                <Button label="Submit Banking Info" block wider l />
            </div>
        </div>
    )
}
