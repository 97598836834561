
const generateApiError = (message: string): any => ({ apierror: { message }});

const convertToApiError = (error: any): any =>  {
  if (error?.apierror?.message) { return error; } // already formated
  const validationErrors = error?.[0]?.constraints;
  const validationError = validationErrors?.[Object.keys(validationErrors)[0]];
  return error?.response?.data || generateApiError(validationError || 'Unknown error. Please use help center.');
}

export {
  generateApiError,
  convertToApiError,
}
