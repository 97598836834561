import React, { useEffect, useState, useContext } from 'react';
import { BackendContext } from './BackendContext';
import { UserRole } from './models/User';





interface MessageEntity {
  type: 'message' | 'quote' | 'sent-offer' | 'offer' | 'declined-offer' | 'offer-accepted',
  text?: string,
  senderAvatar?: string,
  time?: string,
  isMyMessage?: boolean,
};






type MessengerContextType = {
  messages: MessageEntity[],
  isProfileDrawerShown: boolean,
  setIsProfileDrawerShown: Function,
  isProjectDetailDrawerShown: boolean,
  setIsProjectDetailDrawerShown: Function,
  dummySendMessage: Function,
}

const MessengerContext = React.createContext<MessengerContextType | undefined>(undefined);

type MessengerContextProviderWrapperProps = { children: JSX.Element };



const tempAvatarUrlOne = 'https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg';
const tempAvatarUrlTwo = 'https://pbs.twimg.com/profile_images/929936057885384704/HnSIbCf8_400x400.jpg';
const demoUserMessages: MessageEntity[] = [
  {type: 'message',  text: 'What type of fabric??', senderAvatar: tempAvatarUrlOne, time: '12:00 am', isMyMessage: false},
  {type: 'quote'},
  {type: 'message',  text: 'Microfiber', senderAvatar: tempAvatarUrlTwo, time: '12:00 am', isMyMessage: true},
  {type: 'sent-offer'},
  {type: 'declined-offer'},
  {type: 'offer'},
  {type: 'offer-accepted'}
];
const demoServiceProviderMessages: MessageEntity[] = [
  {type: 'message',  text: 'Hello world!', senderAvatar: tempAvatarUrlOne, time: '12:00 am', isMyMessage: true},
  {type: 'quote', text: ''},
  {type: 'message',  text: 'How is it going?', senderAvatar: tempAvatarUrlTwo, time: '12:00 am', isMyMessage: false},
  {type: 'message',  text: 'Going fine! Thanks! How is it going? Giving you an offer.', senderAvatar: tempAvatarUrlOne, time: '12:01 am', isMyMessage: true},
  {type: 'sent-offer'},
  {type: 'message',  text: 'Nothing Much.', senderAvatar: tempAvatarUrlTwo, time: '12:01 am', isMyMessage: false},
  {type: 'offer'},
  {type: 'message',  text: 'Do you like the deal?', senderAvatar: tempAvatarUrlOne, time: '12:02 am', isMyMessage: true},
];




function MessengerContextProviderWrapper({children}: MessengerContextProviderWrapperProps) {
  const [ messages, setMessages ] = useState<MessageEntity[]>([]);
  const [ isProfileDrawerShown, setIsProfileDrawerShown ] = useState<boolean>(false);
  const [ isProjectDetailDrawerShown, setIsProjectDetailDrawerShown ] = useState<boolean>(false);
  const { user } = useContext(BackendContext)!;

  useEffect(() => {
    /** insert dummy data */
    setMessages(demoUserMessages);
  }, []);

  useEffect(() => {
    setMessages(user?.userRole === UserRole.CUSTOMER ? demoUserMessages : demoServiceProviderMessages);
  }, [ user ]);

  const dummySendMessage = (messageText: string) => {
    setMessages(oldArray => [...oldArray, {
      type: 'message',
      text: messageText,
      senderAvatar: tempAvatarUrlTwo,
      time: (new Date()).toISOString(),
      isMyMessage: true,
    }]);
    setMessages(oldArray => [...oldArray, {
      type: 'message',
      text: 'Just a random message',
      senderAvatar: tempAvatarUrlOne,
      time: (new Date()).toISOString(),
      isMyMessage: false,
    }]);
  }


  return (
    <MessengerContext.Provider value={{
      messages,
      isProfileDrawerShown,
      setIsProfileDrawerShown,
      isProjectDetailDrawerShown,
      setIsProjectDetailDrawerShown,
      dummySendMessage,
    }}>
      {children}
    </MessengerContext.Provider>
  );
}

export {
  MessengerContext,
  MessengerContextProviderWrapper,
}

export type {
  MessengerContextType,
};
