import React, { useContext } from 'react';


import './Services.scss';
import Text from '../../../../components/Text/Text';
import { UiContext } from '../../../../contexts/UiContext';
import Button from '../../../../components/Button/Button';


export default function Services() {
  const { profilePageMode } = useContext(UiContext)!;

  return (
    <div className="pdl-profile-profileRightPane-Services">
      <Text primary fontSize={18}>Services</Text>
      <div className="pdl-ServiceListContainer">
        <ServiceItem name="Service 1"/>
        <ServiceItem name="Service 2"/>
        <ServiceItem name="Service 3"/>
        {profilePageMode === 'view' && <ServiceItem name="Service 4"/>}
        {profilePageMode === 'edit' && <Button label="Update Services" iconRight="flat_icon_edit"/>}
      </div>
    </div>
  )
}

function ServiceItem({ name }: { name: string }) {
  return (
    <div className="pdl-ServiceItem">
      <Text b80>{name}</Text>
    </div>
  );
}
