import React, { useContext } from 'react';
import { BackendContext } from '../../contexts/BackendContext';
import { UserRole } from '../../contexts/models/User';
import Customer from './Customer/Customer';


import './Profile.scss';
import ProfileLeftPane from './ProfileLeftPane/ProfileLeftPane';
import ProfileRightPane from './ProfileRightPane/ProfileRightPane';


export default function Profile() {
  const { user } = useContext(BackendContext)!;

  if (user?.userRole === UserRole.CUSTOMER) {
    return (
      <div className="pdl-ProfilePage">
        <Customer/>
      </div>
    );
  }

  return (
    <div className="pdl-ProfilePage">
      <div className="pdl-ProfilePage-LeftPane-wrapper">
        <ProfileLeftPane/>
      </div>
      <div className="pdl-ProfilePage-RightPane-wrapper">
        <ProfileRightPane/>
      </div>
    </div>
  );
}
