import React from 'react';
import Text from '../../components/Text/Text';


import './Home.scss';
import LandingPage from './LandingPage/LandingPage';


export default function Home() {
  return (
    <div className="pdlHome">
      <LandingPage/>
    </div>
  );
}
