import React, { useState } from 'react';

import {
  Modal,
  Carousel as ReactBootstrapCarousel,
} from 'react-bootstrap';

import './ImageListWithModal.scss';
import Avatar from '../Avatar/Avatar';
import Text from '../Text/Text';


type ImageListWithModalTypes = {
  imageUrls: string[],
  limit?: number,
};

export default function ImageListWithModal({ imageUrls, limit = 5 }: ImageListWithModalTypes) {
  const [ showPhotosModal, setShowPhotosModal ] = useState<boolean>(false);

  return (
    <div className="pdl-ImageListWithModal">
      {Array.from(Array(limit - 1).keys()).map((x) =>
        imageUrls[x] && <AvatarWrapper key={x} src={imageUrls[x]} onClick={() => setShowPhotosModal(true)}/>
      )}
      {imageUrls[limit - 1] && <ShowMoreAvatarWrapper src={imageUrls[limit - 1]} onClick={() => setShowPhotosModal(true)}/>}
      <PhotosModal show={showPhotosModal} setShow={setShowPhotosModal} images={imageUrls}/>
    </div>
  );
}

function AvatarWrapper({ src, onClick } : { src: string, onClick: Function }) {
  return (
    <div className="pdl-AvatarWrapper">
      <Avatar h={72} w={60} src={src} onClick={onClick}/>
    </div>
  );
}

function ShowMoreAvatarWrapper({ src, onClick } : { src: string, onClick: Function }) {
  return (
    <div onClick={(_) => onClick()}>
      <div className="pdl-AvatarWrapper">
        <Avatar h={72} w={60} src={src}/>
      </div>
      <div className="pdl-AvatarWrapper pdl-AvatarWrapper-Glass">
        <Text white fontSize={24}>+3</Text>
      </div>
    </div>
  )
}

function PhotosModal({ show, setShow, images }: { show: boolean, setShow: Function, images: string[] }) {

  return (
    <Modal
      dialogClassName="pdl-photo-gallery-modal-dialog"
      contentClassName="pdl-photo-gallery-modal-content"
      show={show}
      onHide={() => setShow(false)}
      size="lg"
    >

      <Modal.Body>
        <ReactBootstrapCarousel controls={true}>
          {images.map((imageUrl, index) =>
            <ReactBootstrapCarousel.Item key={index} interval={6000}>
              <img
                className="d-block w-100"
                height={484}
                src={imageUrl}
                alt={`Slide Indes: ${index}`}
              />
            </ReactBootstrapCarousel.Item>
          )}
        </ReactBootstrapCarousel>
      </Modal.Body>
    </Modal>
  );
}
