import React, { useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import './Login.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Text from '../../components/Text/Text';
import Container from '../../components/Layout/Container';
import LOGIN_IMAGE from '../../assets/svg-bootstrap/login.svg';
import InlineInput from '../../components/InlineInput/InlineInput';
import Button from '../../components/Button/Button';
import { FormCheck } from 'react-bootstrap';
import { BackendContext } from '../../contexts/BackendContext';


export default function Login(){

  const history = useHistory();
  const { login, loginRequest, loginResponse, setLoginRequest } = useContext(BackendContext)!;
  const [ passwordType, setPasswordType ] = useState<'text' | 'password'>('password');

  const changeEmailOrPhoneNumber = (emailOrPhone: string) => {
    setLoginRequest({ ...loginRequest, emailOrPhone });
  };

  const changePassword = (password: string) => {
    setLoginRequest({ ...loginRequest, password });
  };

  const onLoginSuccess = (response: any) => {
    console.log(response);
    history.push(`/messenger`);
  };

  const onLoginClick = () => login().then(onLoginSuccess).catch(console.log);

  const iconRightOnClick = () =>{
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }
  return(
    <div className="pdlLoginForm">
      <Container>
        <Row>
          <Col sm={5} className="login">
          <Text fontWeight={600} fontSize={30} bp={true}>Log In into your account</Text>
          <div style={{marginTop: 20}}></div> {/** just for space */}
          <img src={LOGIN_IMAGE}/>
          </Col>
          <Col sm={6} className="form">
          <InlineInput type="text" placeholder="Enter your email address or phone number" label="Email or Phone" value={loginRequest?.emailOrPhone} onChange={changeEmailOrPhoneNumber}/>
            <div style={{marginTop: 20}}></div> {/** just for space */}
            <InlineInput type={passwordType} placeholder="Enter your password" label="Password" iconRight={passwordType === 'password' ? 'eye_slash' : 'eye'} iconRightOnClick={iconRightOnClick} value={loginRequest?.password} onChange={changePassword}/>
            <div style={{marginTop: 30}}></div> {/** just for space */}
            {loginResponse?.apierror &&
              <>
                <Text danger>{loginResponse.apierror.message}</Text>
                <div style={{marginTop: 30}}></div> {/** just for space */}
              </>
            }
            <Button label="Log In" block wider l onClick={onLoginClick}/>
            <div className="login-utill">
                <FormCheck className="remember" type="checkbox" label="Remember Me"></FormCheck>
                <p className="forget" onClick={() => {history.push("/forget-password")}}>Forget Password ?</p>
            </div>
            <div style={{marginTop: 30}}></div> {/** just for space */}
              <Text fontWeight={600} fontSize={20}>Don't have an account ? <span style={{color:"#1877F2", cursor: "pointer"}} onClick={() => {history.push('/registration')}}> Create account </span></Text>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
