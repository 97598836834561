import React, {useState, useRef, useLayoutEffect} from 'react';
import { Col, Container, Row, Form } from 'react-bootstrap';
import Text from '../../components/Text/Text';
import './PostYourProblem.scss';
import PLUS from '../../assets/svg-bootstrap/upload-plus.svg';
import Button from '../../components/Button/Button';
import IconComponent from '../../components/IconComponent';
import Avatar from '../../components/Avatar/Avatar';
import DateAndTime from './DateAndTime/DateAndTime';


export default function PostYourProblem(){
 
  return(
    <div className="pdl-PostYourProblemPageContainer">
      <div className="pdl-PostYourProblemCard">
        <div className="pdl-CardHeader">
          <Text fontWeight={500} fontSize={30} lineHeight="45px">
            Post your problem
          </Text>
        </div>
        <PdlForm />
      </div>

    </div> 
  );
}

function PdlForm(){
  const [ uploadedImage, setUploadedImage ] = useState<string | undefined>(undefined);

  const dummyCategoryList = ["Choose Category", "Category One", "Category Two", "Category Three", "Category Four"];
  return(
    <div className="pdl-CardBody">
      <Form>
        <PdlFormGroup type="select" label="Choose Category" options={dummyCategoryList}></PdlFormGroup>
        <PdlFormGroup type="select" label="Choose Sub-category" options={dummyCategoryList}></PdlFormGroup>
        <PdlFormGroup type="text" label="Problem Title (Limit 50 characters)" options={["Write here your problem title..."]}></PdlFormGroup>
        <PdlFormGroup type="textarea" label="Problem Description (Limit 250 words)" options={["Write here your problem description..."]} rows={5}></PdlFormGroup>
        <Form.Group>
          <Form.Label>
            <Text fontWeight={500} fontSize={16} lineHeight="24px">Add photo's (Optional)</Text>
          </Form.Label>
          <div className="pdl-AddPhoto">
            {/* <button className="btn" ><img src={PLUS} /></button>
            <Form.File />
            <div style={{width: 24}}/>
            <button className="btn" ><img src={PLUS} /></button>
            <div style={{width: 24}}/>
            <button className="btn" ><img src={PLUS} /></button> */}
            <Uploader />
            <div style={{width: 24}}/>
            <Uploader  />
            <div style={{width: 24}}/>
            <Uploader />
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <Text fontWeight={500} fontSize={16} lineHeight="24px">Available Time</Text>
            </Form.Label>
            <DateAndTime />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <Text fontWeight={500} fontSize={16} lineHeight="24px">Deadline</Text>
          </Form.Label>
          <Form.Control type="date" className="input-box"></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <Text fontWeight={500} fontSize={16} lineHeight="24px">Budget (Optional)</Text>
          </Form.Label>
          <Form.Control type="text" style={{width:262, height:44}} className="input-box" placeholder="$100"></Form.Control>
        </Form.Group>
        <div className="btn-center">
          <Button label="Send to all Pros" wider l></Button>
        </div>
        <div style={{marginTop:45}}></div>
      </Form>
    </div>
  );
}

type Group = {
  type : string,
  label : string,
  options : Array<string>,
  rows? : number,
}

function PdlFormGroup({type, label, options, rows}: Group)
{
  let formControl = null;
  if( type === "select"){
    formControl = (<Form.Control as="select" className="input-box" custom>
                    {options.map(element =>
                      <option>{element}</option>
                    )}
                  </Form.Control>);
  }
  else if( type === "textarea"){
    formControl = (<Form.Control as={type} rows={rows} placeholder={options[0]} className="input-box" style={{height: 138}} />)
  }
  else{
    formControl = (<Form.Control type={type} placeholder={options[0]} className="input-box" maxLength={50} />)
  }
  return(
    <Form.Group>
      <Form.Label>
        <Text fontWeight={500} fontSize={16} lineHeight="24px">{label}</Text>
      </Form.Label>
      {formControl}
    </Form.Group>
  );
}
function Uploader() {
  const inputFile = useRef<HTMLInputElement>(null);
  const [ uploadedImage, setUploadedImage ] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {

  });

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    if (!file) { return; }

    const fileUrl = URL.createObjectURL(file);
    setUploadedImage(fileUrl);
  };

  const onClickUploader = () => inputFile.current?.click();

  return (
    <div>
      <input
        type="file"
        style={{display: 'none'}}
        onChange={handleUpload}
        ref={inputFile}
      />
        <div className={ uploadedImage === undefined ? "btn" : ""} onClick={onClickUploader}>
          {uploadedImage === undefined ?
            <IconComponent name="upload_plus" className="pdl-Icon" />
            :
            <Avatar src={uploadedImage} h={83} w={117} onClick={() => {}}/>
          }
        </div>
    </div>
  );
}