import React, { useState, useRef, useLayoutEffect } from 'react';


import './ProfilePic.scss';
import IconComponent from '../../../../components/IconComponent';
import Avatar from '../../../../components/Avatar/Avatar';


type ProfilePicTypes = {
  onSuccess: Function,
}

export default function ProfilePic({ onSuccess }: ProfilePicTypes) {
  const [ uploadedImage, setUploadedImage ] = useState<string | undefined>(undefined);

  return (
    <div className="pdl-ProfilePic">
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Uploader
          onUploadImage={setUploadedImage}
          uploadedImage={uploadedImage}
        />
      </div>
    </div>
  );
}

function Uploader({ onUploadImage, uploadedImage }:{ onUploadImage: Function, uploadedImage?: string }) {
  const inputFile = useRef<HTMLInputElement>(null);


  useLayoutEffect(() => {

  });

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    if (!file) { return; }

    const fileUrl = URL.createObjectURL(file);
    onUploadImage(fileUrl);
  };

  const onClickUploader = () => inputFile.current?.click();

  return (
    <div>
      <input
        type="file"
        style={{display: 'none'}}
        onChange={handleUpload}
        ref={inputFile}
      />
      <div style={{alignSelf: 'center'}}>
        <div style={{display: 'flex', justifyContent: 'center'}} onClick={onClickUploader}>
          <div className="pdl-PhotoContainer">
            {uploadedImage === undefined ?
              <IconComponent name="camera_fill" className="pdl-Icon"/>
              :
              <Avatar src={uploadedImage} h={70} w={70} rounded onClick={() => {}}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
