import { ValidatorOptions } from "class-validator";

const validatorOptions: ValidatorOptions = {
  stopAtFirstError: true,
  validationError: {
    value: false,
  }
};

const propMapHumanReadable: any = {
  emailOrPhone: 'Email or phone number',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  phone: 'Phone Number',
  email: 'Email',
  postCode: 'Postal code',
};

export {
  validatorOptions,
  propMapHumanReadable,
}
