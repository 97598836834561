import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Badge as ReactBootstrapBadge } from "react-bootstrap";

import "./Subscription.scss";
import Text from "../../components/Text/Text";

import FREE from "../../assets/svg-subscription/free.svg";
import GIFT from "../../assets/svg-subscription/gift.svg";
import QUALITY from "../../assets/svg-subscription/quality.svg";
import CROSS from "../../assets/svg-subscription/cross-square.svg";
import INFORMATION from "../../assets/svg-subscription/information.svg";
import Button from "../../components/Button/Button";

export default function Subscription() {
  return (
    <Container className="pdl-Subscription">
      <div className="header">
        <div className="text-center">
          <Text fontSize={30} fontWeight={600} lineHeight="45px" primary={true}>
            Pricing Plan
          </Text>
        </div>
        <div className="text-center">
          <Row>
            <Col sm={3}></Col>
            <Col sm={6} className="text-center">
              <Text fontSize={14} fontWeight={400} lineHeight="21px" b50={true}>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour,
              </Text>
            </Col>
            <Col sm={3}></Col>
          </Row>
        </div>
      </div>
      <div style={{ marginTop: 32 }}>
        <table className="table pdlTable">
          <thead>
            <tr className="text-center">
              <th scope="col">
                <Text
                  fontSize={30}
                  fontWeight={600}
                  lineHeight="45px"
                  primary={true}
                >
                  Features
                </Text>
              </th>
              <th scope="col">
                <ProBadge text="Basic"></ProBadge>
                <Text
                  fontSize={30}
                  fontWeight={600}
                  lineHeight="45px"
                  primary={true}
                >
                  Free
                </Text>
              </th>
              <th scope="col">
                <ProBadge text="Pro"></ProBadge>
                <Text
                  fontSize={30}
                  fontWeight={600}
                  lineHeight="45px"
                  primary={true}
                >
                  $39.99
                </Text>
              </th>
              <th scope="col">
                <ProBadge text="Pro" plus></ProBadge>
                <Text
                  fontSize={30}
                  fontWeight={600}
                  lineHeight="45px"
                  primary={true}
                >
                  $89.99
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td></td>
              <td>
                {" "}
                <img src={FREE}></img>{" "}
              </td>
              <td>
                {" "}
                <img src={GIFT}></img>
              </td>
              <td>
                {" "}
                <img src={QUALITY}></img>
              </td>
            </tr>
            <tr>
              <td>
                Promotion{" "}
                <img src={INFORMATION} style={{ marginLeft: 16 }}></img>
              </td>
              <td className="text-center">
                {" "}
                <img src={CROSS}></img>{" "}
              </td>
              <td className="text-center">Additional promotion</td>
              <td className="text-center">Full promotion</td>
            </tr>
            <tr>
              <td>
                Promotion{" "}
                <img src={INFORMATION} style={{ marginLeft: 16 }}></img>
              </td>
              <td className="text-center">
                {" "}
                <img src={CROSS}></img>{" "}
              </td>
              <td className="text-center">Additional promotion</td>
              <td className="text-center">Full promotion</td>
            </tr>
            <tr>
              <td>
                Promotion{" "}
                <img src={INFORMATION} style={{ marginLeft: 16 }}></img>
              </td>
              <td className="text-center">
                {" "}
                <img src={CROSS}></img>{" "}
              </td>
              <td className="text-center">Additional promotion</td>
              <td className="text-center">Full promotion</td>
            </tr>
            <tr>
              <td>
                Promotion{" "}
                <img src={INFORMATION} style={{ marginLeft: 16 }}></img>
              </td>
              <td className="text-center">
                {" "}
                <img src={CROSS}></img>{" "}
              </td>
              <td className="text-center">Additional promotion</td>
              <td className="text-center">Full promotion</td>
            </tr>
            <tr>
              <td>
                Promotion{" "}
                <img src={INFORMATION} style={{ marginLeft: 16 }}></img>
              </td>
              <td className="text-center">
                {" "}
                <img src={CROSS}></img>{" "}
              </td>
              <td className="text-center">Additional promotion</td>
              <td className="text-center">Full promotion</td>
            </tr>
            <tr>
              <td>
                Promotion{" "}
                <img src={INFORMATION} style={{ marginLeft: 16 }}></img>
              </td>
              <td className="text-center">
                {" "}
                <img src={CROSS}></img>{" "}
              </td>
              <td className="text-center">Additional promotion</td>
              <td className="text-center">Full promotion</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-center">
                {" "}
                <Button label="Included" outline={false}></Button>{" "}
              </td>
              <td className="text-center">
                <Button label="Start Trial" outline={true}></Button>
              </td>
              <td className="text-center">
                <Button label="Start Trial" outline={true}></Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
}

function ProBadge({
  text,
  plus,
  white,
}: {
  text: string;
  plus?: boolean;
  white?: boolean;
}) {
  const textColor = white ? "#1877f2" : "white";
  return (
    <div className="pdlProBadge">
      <ReactBootstrapBadge variant={`${white ? "light" : "primary"}`}>
        <div style={{ display: "flex" }}>
          <div style={{ fontSize: 39, fontWeight: 700, color: textColor }}>
            {text}
          </div>
          {plus && (
            <div
              style={{
                fontSize: 30,
                marginTop: -4,
                marginLeft: 2,
                fontWeight: 700,
                color: textColor,
              }}
            >
              +
            </div>
          )}
        </div>
      </ReactBootstrapBadge>
    </div>
  );
}
