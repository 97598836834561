import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './PromotionalPage.scss'
import LOGO from './logo.svg';
import IMAGE_ONE from './1.svg';
import IMAGE_TWO from './2.svg';
import IMAGE_THREE from './3.svg';
import IMAGE_FOUR from './4.svg';
import IMAGE_FIVE from './5.svg';
import IMAGE_SIX from './6.svg';
import IMAGE_SEVEN from './7.svg';
import IMAGE_EIGHT from './8.svg';
import IMAGE_NINE from './9.svg';
import IMAGE_TEN from './10.svg';
import APP_STORE from './App-store-badge.png';
import CAPTURE from './Capture.png';
import GOOGLE_PLAY_BADGE from './google-play-badge.png';
import MAP_MOBILE from './map-mobile.png';
import MOBILE_FOOTER from './mobile-footer.svg';
import MOBILE_ONE from './mobile1.svg';
import DOT from './dot.svg';
import PERCENTAGE from './percentage-mobile.svg';

import usePromotionalPage from './PromotionalPageHooks';


export default function PromotionalPageMobile() {
  const {
    email,
    setEmail,
    joinedMailingList,
    onClickMailingListJoin,
  } = usePromotionalPage();

  return (
    <div className="promotionalPage">
      <nav className="nav">
        <div className="container-fluid text-center">
          <img src={LOGO} style={{width:"300px", height:"300px", position:"relative", top:"-110px"}}/>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row text-center col-mobile" style={{ zIndex: 1 }}>
          <div className="col-sm-12" data-aos="zoom-in" style={{ zIndex: 1 }}>
            <h1>Who are we</h1>
            <p>
            Provisorr is the first Service Provider-centric marketplace and community platform. Customers can view and connect with Service Providers and discuss pricing and work availability. Providers bid on projects based on their expertise and schedule. Service Providers can then establish a portfolio of their work, while connecting and sharing with other Service Providers on the platform.
            </p>
            <div className="dot-mobile" style={{ zIndex: 1 }}>
                <img src={DOT} id="right-dot" />
                <div className="follow-email" style={{ zIndex: 1 }}>
                  <p>
                  Service Providers get a $100 promotional credit when they sign up to our mailing list as an early founder when we launch.
                  </p>
                  <a href="#follow" style={{ zIndex: 1 }}> Follow us via email </a>
                  <div className="follow-head-mobile">
                    <p>Get a $100</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="row" data-aos="zoom-in">
          <div className="col-sm-12 mobile-worker">
            <img src={MOBILE_ONE} />
            <div className="d2-mobile">
              <img src={IMAGE_THREE} />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: '-73%' }}>
        <div className="row text-center">
          <div className="col-sm-12" data-aos="zoom-in">
            <h3>How it works</h3>
          </div>
          <div className="col-sm-12 d-flex justify-content-center" data-aos="fade-right">
            <p className="round-mobile">1</p>
          </div>
          <div className="col-sm-12" data-aos="fade-left">
            <h6>Post a problem by describing the problem and posting images</h6>
          </div>
          <div className="col-sm-12" data-aos="zoom-in">
            <img src={IMAGE_FOUR} className="option-img" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-sm-12 d-flex justify-content-center" data-aos="fade-right">
            <p className="round-mobile">2</p>
          </div>
          <div className="col-sm-12" data-aos="fade-left">
            <h6>
            Get bids from qualified Service Providers. Add them to chat to
                discuss pricing, deadlines and additional project details
            </h6>
          </div>
          <div className="col-sm-12" data-aos="zoom-in">
            <img src={IMAGE_NINE} className="option-img" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-sm-12 d-flex justify-content-center" data-aos="fade-right">
            <p className="round-mobile">3</p>
          </div>
          <div className="col-sm-12" data-aos="fade-left">
            <h6>
            Confirm delivery of the service and leave a review and feedback
                for your professional.
            </h6>
          </div>
          <div className="col-sm-12" data-aos="zoom-in">
            <img src={IMAGE_TEN} className="option-img" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 text-center" data-aos="zoom-in">
            <h3>How we are different</h3>
          </div>
          <div className="col-sm-12 text-center" data-aos="zoom-in">
            <img src={IMAGE_FIVE} className="diff-img" />
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3 round-mobile text-center" data-aos="fade-left">
                <img src={IMAGE_SIX} width="14px" />
              </div>
              <div className="col-sm-9" style={{ width: '90%' }} data-aos="fade-up">
                <p style={{ fontSize: 14 }}>
                Competitive Bidding means customers choose the best provider for
                the job at competitive prices from a marketplace of providers
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3 round-mobile text-center" data-aos="fade-left">
                <img src={IMAGE_SEVEN} width="14px" />
              </div>
              <div className="col-sm-9" style={{ width: '90%' }} data-aos="fade-up">
                <p style={{ fontSize: 14 }}>
                We offer an escrow service that ensures both customers and
                Service Providers confirm the completion of the work. Both
                parties test and troubleshoot the work while the provider is on
                site before the work is considered complete
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3 round-mobile-percentage text-center" data-aos="fade-left">
              <img src={PERCENTAGE} />
              </div>
              <div className="col-sm-9" style={{ width: '90%' }} data-aos="fade-up">
                <p style={{ fontSize: 14 }}>
                We charge no fees on our Service Providers. On Provisorr our
                providers work for themselves and are paid for the entirety of
                their work
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="follow" data-aos="fade-up">
        <div className="row">
          <div className="col-sm-12 text-center">
            { joinedMailingList &&
              <div className="mail-polished-mobile text-center">
                <p>Thanks for Joining</p>
              </div>
            }
            { !joinedMailingList &&
              <div className="mail-polished-mobile text-center">
                <p>Join Our Mailing List</p>
                <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <button className="btn btn-primary" onClick={(_) => onClickMailingListJoin()}>Join Us</button>
              </div>
            }
          </div>
        </div>
      </section>
      <div style={{ height: 100 }}></div>

      <section className="mobile-footer">
        <div className="container-fluid">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              <div style={{ height: 25 }}></div>
              <p ><b>Android and iOS applications coming soon...</b></p>
              <div className="d-flex flex-row">
                <img src={GOOGLE_PLAY_BADGE} className="store-icon-polished-mobile" />
                <img src={APP_STORE} className="store-icon-polished-mobile" style={{paddingLeft:10}}/>
              </div>
            </div>
            <div className="d-flex">
              <img src={CAPTURE} className="mobile-footer-device" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
