import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import './DateAndTime.scss';
import IconComponent from '../../../components/IconComponent';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';


export default function DateAndTime() {
  const [ modalShown, setModalShown ] = useState<boolean>(false);
  
  const [ satDay, setSatDay ] = useState<boolean>();
  const [ sunDay, setSunDay ] = useState<boolean>();
  const [ monDay, setMonDay ] = useState<boolean>();
  const [ tueDay, setTueDay ] = useState<boolean>();
  const [ wedDay, setWedDay ] = useState<boolean>();
  const [ thuDay, setThuDay ] = useState<boolean>();
  const [ friDay, setFriDay ] = useState<boolean>();
  
  const [ morning, setMorning ] = useState<boolean>();
  const [ afternoon, setAfternoon ] = useState<boolean>();
  const [ evening, setEvening ] = useState<boolean>();

  return (
    <div className="pdl-CheckoutOrderPage-UserInfo-DateAndTime">
      <div className="input-box">
        <Text fontSize={14} lineHeight="21px">Select your availablity</Text>
      <IconComponent name="calender" className="pdl-icon" onClick={() => setModalShown(!modalShown)}/>
      </div>

      <Modal
        dialogClassName=""
        contentClassName=""
        show={modalShown}
        onHide={() => setModalShown(false)}
      >
        <Modal.Header className="pdl-CheckoutOrderPage-UserInfo-DateAndTime-modalHeader">
          <Text fontWeight={500} fontSize={20} centered>Choose your available time</Text>
        </Modal.Header>
        <Modal.Body className="pdl-CheckoutOrderPage-UserInfo-DateAndTime-modalBody">

          <Text fontSize={14}>Choose your availablities</Text>
          <div style={{ margin: '16px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div> 
                <Button label="Sat" block outline={!satDay} onClick={() => setSatDay(!satDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
            <div> 
                <Button label="Sun" block outline={!sunDay} onClick={() => setSunDay(!sunDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
            <div> 
                <Button label="Mon" block outline={!monDay} onClick={() => setMonDay(!monDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
            <div> 
                <Button label="Tue" block outline={!tueDay} onClick={() => setTueDay(!tueDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
            <div> 
                <Button label="Wed" block outline={!wedDay} onClick={() => setWedDay(!wedDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
            <div> 
                <Button label="Thu" block outline={!thuDay} onClick={() => setThuDay(!thuDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
            <div> 
                <Button label="Fri" block outline={!friDay} onClick={() => setFriDay(!friDay)}/>
                <div style={{ minWidth: 16 }}></div>
            </div>
          </div>

          <Text fontSize={14}>Choose your preferred time</Text>
          <div>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <div style={{display: 'flex', flexDirection:"row"}}> 
                <Button label="Morning" block outline={!morning} onClick={() => setMorning(!morning)}/>
                <div style={{ minWidth: 10 }}></div>
              </div>
              <div style={{display: 'flex', flexDirection:"row"}}> 
                <Button label="Afternoon" block outline={!afternoon} onClick={() => setAfternoon(!afternoon)}/>
                <div style={{ minWidth: 10 }}></div>
              </div>
              <div style={{display: 'flex', flexDirection:"row"}}> 
                <Button label="Evening" block outline={!evening} onClick={() => setEvening(!evening)}/>
                <div style={{ minWidth: 10 }}></div>
              </div>
            </div>
          </div>
          <div style={{ height: 32 }}></div>
          <Button label="Confirm Schedule" block onClick={() => setModalShown(false)}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}
