import React, { useContext } from 'react';


import './Gallery.scss';
import Text from '../../../../components/Text/Text';
import ImageListWithModal from '../../../../components/ImageListWithModal/ImageListWithModal';
import FileUploaderButton from '../../../../components/FileUploaderButton/FileUplaoderButton';
import { UiContext } from '../../../../contexts/UiContext';


export default function Gallery() {
  const { profilePageMode } = useContext(UiContext)!;

  return (
    <div className="pdl-profile-profileRightPane-Gallery">
      <Text primary fontSize={18}>Gallery</Text>
      <div style={{ display: 'flex', alignItems: 'center', }}>
        <ImageListWithModal
          imageUrls={[
            'https://iso.500px.com/wp-content/uploads/2018/03/500px_blog_spring_pictures.jpg',
            'https://drifterplanet.com/wp-content/uploads/2017/12/Cancun-Private-Beach-Party.jpg',
            'http://localhost:3000/static/media/random-sofa.623a371e.png',
            'https://www.denverpost.com/wp-content/uploads/2018/01/day_in_pictures_010818_001.jpg?w=620',
            'https://www.planetware.com/wpimages/2020/01/india-in-pictures-beautiful-places-to-photograph-taj-mahal.jpg',
            'https://cdn.pixabay.com/photo/2015/09/09/16/05/forest-931706__340.jpg',
          ]}
        />
        {profilePageMode === 'edit' &&
          <div style={{ marginTop: 6 }}>
            <FileUploaderButton h={70} w={60} onUploadImage={() => {}}/>
          </div>
        }
      </div>
    </div>
  )
}
