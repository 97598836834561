import React, { useContext } from 'react';


import Badges from './Badges/Badges';
import AboutMe from './AboutMe/AboutMe';
import ContactInfo from './ContactInfo/ContactInfo';
import Distance from './Distance/Distance';
import { UiContext } from '../../../../contexts/UiContext';


export default function BottomCard() {
  const { profilePageMode } = useContext(UiContext)!;

  return (
    <div className="pdl-ProfilePage-BottomCard">
      {profilePageMode === 'view' && <Badges/>}
      <AboutMe/>
      <ContactInfo/>
      <Distance/>
    </div>
  )
}
