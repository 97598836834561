import React, { useContext, useState } from 'react';

import {
  ProgressBar as ReactBootstrapProgessBar
} from 'react-bootstrap';

import './Distance.scss';
import Text from '../../../../../components/Text/Text';
import RangeSlider from '../../../../../components/RangeSlider/RangeSlider';
import { UiContext } from '../../../../../contexts/UiContext';


export default function Distance() {
  const { profilePageMode } = useContext(UiContext)!;
  const [ distance, setDistance ] = useState<number>(35);

  return (
    <div className="pdl-ProfilePage-BottomCard-Distance">
      <Text
        primary
        fontSize={18}
      >
        Distance
      </Text>
      <div style={{ height: 16 }}>{/* just for space */}</div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <text> 0 km </text>
        <div style={{ flex: 1, margin: 8, marginTop: 4 }}>
          {profilePageMode === 'view' && <ReactBootstrapProgessBar now={35} label={`${35} km`}/>}
          {profilePageMode === 'edit' && <RangeSlider val={distance} onChange={setDistance}/>}
        </div>
        <text> 100 km </text>
      </div>
    </div>
  )
}
