import { IsNotEmpty } from "class-validator";

import { IsNotEmptyAfterTrim } from "../../ClassValidator/IsNotEmptyAfterTrim";

import { IApiError } from "./ApiError";
import { IUser } from "./User";


interface ILoginRequest {
  emailOrPhone?: string,
  password?: string,
};

class LoginRequest implements ILoginRequest {
  
  @IsNotEmptyAfterTrim()
  emailOrPhone?: string;

  @IsNotEmpty({
    message: 'Password is empty',
  })
  password?: string;

  constructor(x: ILoginRequest) {
    this.emailOrPhone = x.emailOrPhone;
    this.password = x.password;
  }
}


interface ILoginResponse {
  authToken?: string,
  userId: Number,
  willExpireAt: string,
  message: string,
  apierror: IApiError,
};

interface ILoginResponseCustomized extends ILoginResponse {
  user?: IUser,
}

export type {
  ILoginRequest,
  ILoginResponse,
  ILoginResponseCustomized,
}

export {
  LoginRequest,
}
