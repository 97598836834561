import React from 'react';

import Slider from 'react-rangeslider';

import './RangeSlider.scss';


type RangeSliderProps = {
  min?: number,
  max?: number,
  val: number,
  onChange: (val: number) => void,
};

export default function RangeSlider({ min, max, val, onChange }: RangeSliderProps) {
  return (
    <div className="pdl-RangeSlider">
      <Slider min={0} max={100} value={val} onChange={onChange} handleLabel={val.toString()}/>
    </div>
  );
}
