import React, { useState } from 'react';


import './UpdatePassword.scss';
import Text from '../../../../components/Text/Text';
import BlockInput from '../../../../components/BlockInput/BlockInput';
import Button from '../../../../components/Button/Button';



export default function UpdatePassword() {
  const [ tempCurrentPassword, setTempCurrentPassword ] = useState<string>('');
  const [ tempNewPassword, setTempNewPassword ] = useState<string>('');
  const [ tempConfirmPassword, setTempConfirmPassword ] = useState<string>('');

  return (
    <div className="pdl-profile-profileRightPane-UpdatePassword">
      <Text primary fontSize={18}>Update your password</Text>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <div>
          <div style={{ marginTop: 8 }}>
            <Text>Current Password: </Text>
          </div>
          <div style={{ marginTop: 12 }}>
            <Text>New Password: </Text>
          </div>
          <div style={{ marginTop: 14 }}>
            <Text>Confirm Password: </Text>
          </div>
        </div>
        <div style={{ marginLeft: 10, flex: 1 }}>
          <div style={{ marginTop: 4 }}>
            <BlockInput type="password" primary value={tempCurrentPassword} placeholder="Current password" onChange={setTempCurrentPassword}/>
          </div>
          <div style={{ marginTop: 4 }}>
            <BlockInput type="password" primary value={tempNewPassword} placeholder="New password" onChange={setTempNewPassword}/>
          </div>
          <div style={{ marginTop: 4 }}>
            <BlockInput type="password" primary value={tempConfirmPassword} placeholder="Confirm password" onChange={setTempConfirmPassword}/>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row-reverse' }}>
        <Button label="Update"/>
      </div>
    </div>
  )
}
