import React, { useContext } from 'react';

import './InlineInput.scss';

import Text from '../../components/Text/Text';
import IconComponent, { SVGIconNames } from '../IconComponent';


type InputProps = {
  label: string,
  type: string,
  placeholder: string,
  iconRight?: SVGIconNames,
  iconRightOnClick?: Function,
  value?: string,
  onChange?: Function,
  onChangeCountryCode?: (countryCode: string) => void,
}

export default function InlineInput({label, type, placeholder, iconRight, iconRightOnClick, value, onChange, onChangeCountryCode} : InputProps){
  
  if( type === "tel"){
    return (
      <div className="pdlInlineInput">
        <Text fontWeight={700}>{label}</Text>
        <div className="inline_input">
        <select onChange={(e) => onChangeCountryCode?.(e.target.value)}>
          <option value="+1">+1</option>
          <option value="+880">+880</option>
        </select>
        <span></span>
        <input type="number" placeholder={placeholder} value={value} onChange={(e) => onChange?.(e.target.value)}></input>
        </div>
        <div className="line"></div>
      </div>
    );
  }
  return(
    <div className="pdlInlineInput">
      <Text fontWeight={700}>{label}</Text>
      <div className="inline_input">
        <input type={type} placeholder={placeholder} value={value} onChange={(e) => onChange?.(e.target.value)}></input>
        {iconRight && <IconComponent name={iconRight} className="iconRight" onClick={iconRightOnClick}/>}
      </div>
      <div className="line"></div>
    </div>
  );
}
