import { element } from "prop-types";
import React from "react";

import "./Service.scss";
import Text from "../../../../components/Text/Text";
import LOGO from "../../../../assets/provisorr-logo.png";

type ServiceProp = {
  title: string;
  margine?: number;
  card: ServiceCardProp[];
};
export default function Service({ title, margine = 0, card }: ServiceProp) {
  return (
    <div className="pdlService" style={{ marginTop: margine }}>
      <Text fontWeight={500} fontSize={24} lineHeight="36px" bp>
        {title}
      </Text>
      <div className="cardItems">
        {card.map((element) => (
          <ServiceCard
            description={element.description}
            imagePath={element.imagePath}
          ></ServiceCard>
        ))}
      </div>
    </div>
  );
}

type ServiceCardProp = {
  description: string;
  imagePath: any;
};
function ServiceCard({ description, imagePath }: ServiceCardProp) {
  return (
    <div className="card" style={{ marginRight: 33 }}>
      <img src={LOGO} />
      <div className="text-center" style={{ marginTop: 8 }}>
        <Text fontSize={16} lineHeight="24px">
          {description}
        </Text>
      </div>
    </div>
  );
}
