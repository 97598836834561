import React, { useContext, useState } from 'react';


import './LandingPage.scss';
import LogoPng from '../../../assets/provisorr-logo.png';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import Carousel from '../../../components/Carousel/Carousel';
import CategorySlider from '../../../components/CategorySlider/CategorySlider';
import Container from '../../../components/Layout/Container';
import LocationPicker from '../../../components/Misc/LocationPicker/LocationPicker';
import SearchBar from '../../../components/Misc/SearchBar/SearchBar';
import HappyFaceSlider from '../../../components/HappyFaceSlider/HappyFaceSlider';
import Avatar from '../../../components/Avatar/Avatar';
import { UiContext } from '../../../contexts/UiContext';
import Grid, { GridItem } from '../../../components/Layout/Grid';
import IconComponent from '../../../components/IconComponent';
import { SVGIconNames } from '../../../components/IconComponent';


export default function LandingPage() {
  return (
    <div className="pdlLandingPage">
      <TopBar/>
      <Carousel/>
      <LocationNSearchWrapper/>
      <CategorySliderWrapper/>
      <HowItWorks/>
      <StatisticsOverview/>
      <HappyFaces/>
      <Footer/>
    </div>
  );
}

function TopBar() {
  const [ searchText, setSearchText ] = useState<string>('');
  const { scrollY } = useContext(UiContext)!;

  return (
    <div className="pdlTopBar sticky-top">
      <div className="pdlLogoHolder">
        <img height={80} width={180} src={LogoPng}/>
      </div>
      <div className="pdlLocationPlaceHolder">
        {scrollY > 300 && <LocationPicker locationName="Albert Canada" s/>}
      </div>
      <div className="pdlSearchBarPlaceHolder">
        {scrollY > 300 && <SearchBar v={searchText} onChange={setSearchText} s/>}
      </div>
      <div className="pdlButtonsHolder">
        <div className="pdlButtonContainer" style={{cursor: 'pointer'}}>
          <Text fontSize={15} fontWeight={530}>All Services</Text>
        </div>
        <div className="pdlButtonContainer">
          <Button label="Log In" s outline wider/>
        </div>
        <Button label="Post Your Problem" s wider/>
      </div>
    </div>
  );
}

function LocationNSearchWrapper() {
  const [ searchText, setSearchText ] = useState<string>('');

  return (
    <div className="pdlLocationNSearchWrapper">
      <LocationPicker locationName="Albert Canada"/>
      <div style={{width: 24}}></div> {/** just for space */}
      <div className="pdlSearchBarContainer">
        <SearchBar v={searchText} onChange={setSearchText}/>
      </div>
    </div>
  );
}

function CategorySliderWrapper() {
  return (
    <div className="pdlCategorySliderWrapper">
      <Container>
        <CategorySlider/>
      </Container>
    </div>
  )
}


function HowItWorks() {
  const howItWorksSteps = [{
    header: 'Post a Problem',
    p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sagittis tempor tempus.'
  }, {
    header: 'Get Bids from the PROs',
    p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sagittis tempor tempus. Duis sit amet suscipit odio.'
  }, {
    header: 'Negotiate and Place Order',
    p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sagittis tempor tempus. Duis sit amet suscipit odio. Morbi nec nisi ut augue ullamcorper viverra. Nulla a risus diam.'
  }];

  return (
    <div className="pdlHowItWorks">
      <Container>
        <Text fontSize={32} fontWeight={500} lineHeight={'38px'}> How it Works </Text>
        <div style={{height: 24}}>{/** Just for space */}</div>
        <Grid>
          <GridItem>
            <Avatar
              src={'https://ec.europa.eu/easme/sites/easme-site/files/styles/large/public/field/image/build-up-skills-construction-workers.jpg?itok=xRwBpqdG'}
              h={370}
              w={554}
            />
            <div className="pdlPlayBtnContainer">
              <div className="pdlPlayBtnInnerContainer">
                <div className="pdlPlayBtn">
                  <IconComponent name="play_arrow" className="pdlIcon"/>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem>
            <div className="pdlHowItWorksStepsContainer">
              {howItWorksSteps.map((x, i) =>
                <div>
                  <Grid>
                    <GridItem size={2}>
                      <div className="pdlNumberPill">
                        <Text fontSize={18} white centered>{i+1}</Text>
                      </div>
                    </GridItem>
                    <GridItem size={10}>
                      <Text fontSize={18} lineHeight="27px"> {x.header} </Text>
                      <div style={{marginTop: 8}}>
                        <Text fontSize={14} lineHeight="140%"> {x.p} </Text>
                      </div>
                    </GridItem>
                  </Grid>
                  <div style={{height: 40}}>{/** just for space */}</div>
                </div>
              )}
            </div>
          </GridItem>

        </Grid>
      </Container>
      <div style={{height: 80}}>{/** Just for space */}</div>
    </div>
  );
}


function StatisticsOverview() {
  const items = [{
    count: '15,000 +',
    label: 'Service Providers',
  }, {
    count: '1,00,000 +',
    label: 'Order Served',
  }, {
    count: '76,000 +',
    label: '5 Star Received',
  }]
  return (
    <div className="pdlStatisticsOverviewContainer">
      <Container>
        <div className="pdlStatisticsOverview">
          {items.map((item) =>
            <div className="pdlItem">
              <Text fontSize={42} bp fontWeight={600} centered>{item.count}</Text>
              <Text fontSize={18} b80 centered>{item.label}</Text>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}


function HappyFaces() {
  return (
    <div className="pdlHappyFaces">
      <Text fontSize={32} fontWeight={500} centered>Some Happy Faces</Text>
      <div style={{height: 32}}>{/** Just for space */}</div>
      <Container>
        <HappyFaceSlider/>
      </Container>
      <div className="pdlAvatarListContainer">
        <div className="pdlAvatarContainer">
          <Avatar disabled rounded h={64} w={64} src={'https://outpostrecruitment.com/wp-content/uploads/2019/05/Ruairi-Spillane.png'}/>
        </div>
        <div className="pdlAvatarContainer">
          <Avatar disabled rounded h={64} w={64} src={'https://bpconf.com/wp-content/uploads/profile/1011.jpg'}/>
        </div>
        <div className="pdlAvatarContainer">
          <Avatar rounded bg h={120} w={120} src={'https://www.tribeofnoise.com/media/images/profile/44398/1475730433.jpg'}/>
        </div>
        <div className="pdlAvatarContainer">
          <Avatar disabled rounded h={64} w={64} src={'https://outpostrecruitment.com/wp-content/uploads/2019/05/Ruairi-Spillane.png'}/>
        </div>
        <div className="pdlAvatarContainer">
          <Avatar rounded disabled h={64} w={64} src={'https://bpconf.com/wp-content/uploads/profile/1011.jpg'}/>
        </div>
      </div>
    </div>
  );
}


function Footer() {
  const socialIcons: SVGIconNames[] = ['facebook', 'twitter', 'instagram'];

  return (
    <div className="pdlFooter">
      <Container>
        <div className="pdlContentsContainer">
          <Grid>
            <GridItem>
              <img height={80} width={180} src={LogoPng}/>
              <div style={{height: 40}}>{/** just for space 40px */}</div>
              <div className="pdlSocialIconsContainer">
                {socialIcons.map((s) =>
                  <div className="pdlSocialIconWrapper">
                    <IconComponent name={s} className=""/>
                  </div>
                )}
              </div>
              <div style={{height: 40}}>{/** just for space 40px */}</div>
              <Text fontSize={14}>Copyright 2020 © Provisorr</Text>
            </GridItem>

            <GridItem>
              <Text fontSize={18} fontWeight={500} lineHeight="27px">About Provisorr</Text>
              <div style={{height: 12}}>{/** just for space */}</div>
              <Text fontSize={14} lineHeight="21px">About Us</Text>
              <div style={{height: 12}}>{/** just for space */}</div>
              <Text fontSize={14} lineHeight="21px">FAW</Text>
              <div style={{height: 12}}>{/** just for space */}</div>
              <Text fontSize={14} lineHeight="21px">Terms of Services</Text>
              <div style={{height: 12}}>{/** just for space */}</div>
              <Text fontSize={14} lineHeight="21px">Privacy Policy</Text>
            </GridItem>
            <GridItem>
              <Text fontSize={18} fontWeight={500} lineHeight="27px">Contact Us</Text>
              <div style={{height: 12}}>{/** just for space */}</div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <IconComponent name="call" className="pdlContactIcon"/>
                <Text fontSize={14} lineHeight="21px"> +880 0000-000000 </Text>
              </div>
              <div style={{height: 12}}>{/** just for space */}</div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <IconComponent name="email" className="pdlContactIcon"/>
                <Text fontSize={14} lineHeight="21px"> contact@provisorr.com </Text>
              </div>
              <div style={{height: 12}}>{/** just for space */}</div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <IconComponent name="location_on_outline" className="pdlContactIcon"/>
                <Text fontSize={14} lineHeight="21px">
                  Floor 4, Wakil Tower, Ta 131
                  Gulshan Badda Link Road
                  Dhaka 1212
                </Text>
              </div>
            </GridItem>

            <GridItem>
              <Text fontSize={18} fontWeight={500} lineHeight="27px">App Download</Text>
              <div style={{height: 50, width: 170, marginTop: 20, borderRadius: 8, backgroundColor: 'white'}}>{/** GooglePlay Btn Placeholder */}</div>
              <div style={{height: 50, width: 170, marginTop: 20, borderRadius: 8, backgroundColor: 'white'}}>{/** AppStore Btn Placeholder */}</div>
            </GridItem>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
