import React from 'react';


import './OrderSummary.scss';
import Text from '../../../components/Text/Text';
import BlockInput from '../../../components/BlockInput/BlockInput';
import Button from '../../../components/Button/Button';
import USE_PAYPAL from '../../../assets/paypal-btn-img.png';
import USE_CARD from '../../../assets/card-payment-btn-img.png';



export default function OrderSummary() {
  return (
    <div className="pdl-CheckoutOrderPage-OrderSummary">
      <div className="pdl-header">
        <Text
          primary
          fontSize={18}
          centered
        >
          Order Summary
        </Text>
      </div>
      <div className="pdl-body">
        <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex' }}>
          <Text> Order Number: </Text>
          <div style={{ width: 8 }}></div>
          <Text b50> #93874 </Text>
        </div>

        <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex', alignItems: 'center' }}>
          <BlockInput value="" onChange={() => {}} placeholder="Add Promo"/>
          <div style={{ width: 16 }}></div>
          <Button label="Apply" outline s/>
        </div>

        <div className="pdl-calculation">
          <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text> Sub-Total : </Text>
            <div style={{ width: 8 }}></div>
            <Text b50> $100 </Text>
          </div>
          <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text> Service Fee :</Text>
            <div style={{ width: 8 }}></div>
            <Text b50> $10 </Text>
          </div>
          <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text> Maintanance Fee : </Text>
            <div style={{ width: 8 }}></div>
            <Text b50> $5 </Text>
          </div>
        </div>
        <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Text fontWeight={600}> Total : </Text>
          <div style={{ width: 8 }}></div>
          <Text bp fontWeight={600}> $115 </Text>
        </div>
        <Text b50 fontSize={12}>( Incl. all applicable Taxes )</Text>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 24 }}>
          <img src={USE_PAYPAL} className="pdl-image-btn"/>
          <img src={USE_CARD} className="pdl-image-btn"/>
        </div>

      </div>
    </div>
  );
}
