import React from 'react';

import {
  Badge as ReactBootstrapBadge,
} from 'react-bootstrap';

import './Badges.scss';
import UserBadgeGoldStar from '../../../../../assets/user-badges/badge-gold-star.svg';
import UserBadgeVerified from '../../../../../assets/user-badges/badge-verified.png';
import Text from '../../../../../components/Text/Text';


export default function Badges() {
  return (
    <div className="pdl-ProfilePage-BottomCard-Badges">
      <Text
        primary
        fontSize={18}
      >
        Badges
      </Text>
      <div style={{ paddingLeft: 72, paddingRight: 72 }}>
        <UserBadges pro/>
      </div>
    </div>
  )
}


/** copied and slightly modified (only sizes) from components/Userbadge. Should be a generic component */
type UserBadgesTypes = {
  subscriptionOnly?: boolean,
  pro?: boolean,
  proPlus?: boolean,
  white?: boolean,
};

function UserBadges({ subscriptionOnly, pro, proPlus, white }: UserBadgesTypes) {
  return (
    <div className="pdlUserBadges">
      {(pro || proPlus) && <ProBadge plus={proPlus} white={white}/>}
      {!subscriptionOnly && <img height={44} src={UserBadgeVerified} style={{margin: 4}}/>}
      {!subscriptionOnly && <img height={44} src={UserBadgeGoldStar} style={{margin: 4}}/>}
    </div>
  );
}

function ProBadge({ plus, white } : { plus?: boolean, white?: boolean }) {
  const textColor = white ? '#1877f2' : 'white';
  return (
    <div className="pdlProBadge">
      <ReactBootstrapBadge variant={`${white ? 'light' : 'primary'}`}>
        <div style={{ display: 'flex'}}>
          <div style={{fontSize: 32, fontWeight: 700, color: textColor }}>PRO</div>
          {plus && <div style={{fontSize: 16, marginTop: -4, marginLeft: 2, fontWeight: 700, color: textColor }}>+</div>}
        </div>
      </ReactBootstrapBadge>
    </div>
  );
}
