import React, { useContext, useState } from 'react';


import './AboutMe.scss';
import Text from '../../../../../components/Text/Text';
import { UiContext } from '../../../../../contexts/UiContext';
import BlockInput from '../../../../../components/BlockInput/BlockInput';


export default function AboutMe() {
  const { profilePageMode } = useContext(UiContext)!;
  const [ tempAboutMe, setTempAboutMe ] = useState<string>('');

  return (
    <div className="pdl-ProfilePage-BottomCard-AboutMe">
      <Text
        primary
        fontSize={18}
      >
        About me
      </Text>

      <div style={{ height: 16 }}>{/** just for space */}</div>

      {profilePageMode === 'view' &&
        <Text bp>
          There are many variations of passages of Lorem Ipsum available,
          but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look.
        </Text>
      }
      {profilePageMode === 'edit' &&
        <BlockInput primary type="textarea" value={tempAboutMe} onChange={setTempAboutMe} placeholder="Tell customer about yourself..."/>
      }
    </div>
  );
}
