import React, { useContext, useState } from 'react';


import './ContactInfo.scss';
import Text from '../../../../../components/Text/Text';
import { UiContext } from '../../../../../contexts/UiContext';
import BlockInput from '../../../../../components/BlockInput/BlockInput';


function InfoItemEditableWrapper({ label, children }: { label: string, children: React.ReactChild }) {
  return (
    <div style={{ display: 'flex', marginBottom: 16 }}>
      <div style={{ marginTop: 4 }}>
        <Text bp> {label}: </Text>
      </div>
      <div style={{ width: 8 }}></div>
      {children}
    </div>
  );
}

function ContactInfoEditable() {
  const [ tempEmail, setTempEmail ] = useState<string>('');
  const [ tempPhone, setTempPhone ] = useState<string>('');
  const [ tempAddress, setTempAddress ] = useState<string>('');
  const [ tempCity, setTempCity ] = useState<string>('');
  const [ tempStateOrProvince, setTempStateOrProvince ] = useState<string>('');
  const [ tempPostalCode, setTempPostalCode ] = useState<string>('');

  return (
    <div className="pdl-ProfilePage-BottomCard-Contact">
      <InfoItemEditableWrapper label={'Email'}>
        <BlockInput primary placeholder="Email" value={tempEmail} onChange={setTempEmail}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'Phone'}>
        <BlockInput primary placeholder="Phone" value={tempPhone} onChange={setTempPhone}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'Address'}>
        <BlockInput primary placeholder="Address" value={tempAddress} onChange={setTempAddress}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'City'}>
        <BlockInput primary placeholder="City" value={tempCity} onChange={setTempCity}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'State/Province'}>
        <BlockInput primary placeholder="State" value={tempStateOrProvince} onChange={setTempStateOrProvince}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'Postal Code'}>
        <BlockInput primary placeholder="Postal" value={tempPostalCode} onChange={setTempPostalCode}/>
      </InfoItemEditableWrapper>
    </div>
  );
}

function InfoItem({ label, value }: { label: string, value: string }) {
  return (
    <div style={{ display: 'flex' }}>
      <Text bp> {label}: </Text>
      <div style={{ width: 8 }}></div>
      <Text b50> {value} </Text>
    </div>
  );
}

export default function ContactInfo() {
  const { profilePageMode } = useContext(UiContext)!;
  if (profilePageMode === 'edit') { return <ContactInfoEditable />; }

  return (
    <div className="pdl-ProfilePage-BottomCard-Contact">
      <InfoItem label={'Email'} value={'simple@gmail.com'}/>
      <InfoItem label={'Phone'} value={'+1 604-945-6585'}/>
      <InfoItem label={'Address'} value={'4034  Royal Avenue'}/>
      <InfoItem label={'City'} value={'New Westminster'}/>
      <InfoItem label={'State/Province'} value={'British Columbia'}/>
      <InfoItem label={'Postal Code'} value={'V3L 5H1'}/>
    </div>
  )
}
