import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AOS from 'aos';


export default function usePromotionalPage() {
  const [ email, setEmail ] = useState<string>('');
  const [ joinedMailingList, setJoinedMailingList ] = useState<boolean>(false);

  useEffect(()=>{
    AOS.init({
      offset: 250,
      duration: 1200,
      once: true,
    });
  });

  const _isEmail = function(email: string) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  const onClickMailingListJoin = function () {
    if (!_isEmail(email)) { return; }
    axios.post<any>('https://api.provisorr.com:8090/provisorr/api/help', { email }).then(_ => {
      setEmail('');
      setJoinedMailingList(true);
    }).catch(_ => {
    });
  };

  return {
    email,
    setEmail,
    joinedMailingList,
    onClickMailingListJoin,
  };
}
