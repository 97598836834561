import React, { useState, useContext } from 'react';
import {
  ButtonGroup,
  Button as ReactBootstrapButton,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';

import './Filter.scss';
import Text from '../../../../../components/Text/Text';
import IconComponent from '../../../../../components/IconComponent';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { BackendContext } from '../../../../../contexts/BackendContext';
import { UserRole } from '../../../../../contexts/models/User';


export default function Filter() {
  const { user } = useContext(BackendContext)!;
  const [ filterPopoverShown, setFilterPopoverShown ] = useState<boolean>(false);
  const [ tempRecencySelected, setTempRecencySelected ] = useState<boolean>(false);
  const [ tempRating, setTempRating ] = useState<boolean>(false);
  const [ tempPrice, setTempPrice ] = useState<boolean>(false);

  const getCheckBoxComponent = (name: string, checked: boolean, onClick: Function) => {
    return (
      <div style={{display: 'flex', margin: 8}}>
        <CheckBox checked={checked} onClick={() => onClick((cv: boolean) => !cv)}/>
        <div style={{width: 12}}>{/** Just for space */}</div>
        <Text>{name}</Text>
      </div>
    );
  };

  return (
    <div className="pdlFilters">
      <div className="pdlButtonGroupContainer">
        <ButtonGroup aria-label="First group">
          {/* <Button label="Active" outline block/>{' '}
          <Button label="All Bids" block/>{' '} */}
          {user?.userRole === UserRole.CUSTOMER && <ReactBootstrapButton variant="outline"> Active(4) </ReactBootstrapButton>}
          {user?.userRole === UserRole.CUSTOMER && <ReactBootstrapButton> All Bids(24) </ReactBootstrapButton>}
          {user?.userRole === UserRole.SERVICE_PROVIDER && <ReactBootstrapButton> Active(4) </ReactBootstrapButton>}
          {user?.userRole === UserRole.SERVICE_PROVIDER && <ReactBootstrapButton variant="outline"> My Bids(24) </ReactBootstrapButton>}
          {user?.userRole === UserRole.SERVICE_PROVIDER && <ReactBootstrapButton variant="outline"> New Bids(35) </ReactBootstrapButton>}
        </ButtonGroup>
      </div>
      <OverlayTrigger trigger="click" placement="bottom" show={filterPopoverShown} overlay={
        <Popover id="popover-basic">
          <Popover.Content>
            {getCheckBoxComponent('Recency', tempRecencySelected, setTempRecencySelected)}
            {getCheckBoxComponent('Rating', tempRating, setTempRating)}
            {getCheckBoxComponent('Price', tempPrice, setTempPrice)}
          </Popover.Content>
        </Popover>
      }>
        <IconComponent name="flat_icon_filter" className="pdlMoreFilterIcon" onClick={() => setFilterPopoverShown((cv) => !cv)}/>
      </OverlayTrigger>

    </div>
  );
}
