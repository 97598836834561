import React, { useContext, useState } from 'react';


import './TopCard.scss';
import Avatar from '../../../../components/Avatar/Avatar';
import Text from '../../../../components/Text/Text';
import IconComponent from '../../../../components/IconComponent';
import { UiContext } from '../../../../contexts/UiContext';
import ProfilePic from './ProfilePic/ProfilePic';
import BlockInput from '../../../../components/BlockInput/BlockInput';


function PictureAndName() {
  const [ tempName, setTempName ] = useState<string>('');
  const { profilePageMode, enableProfilePageEditMode } = useContext(UiContext)!;

  return (
    <div className="pdl-picture-container">
      <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <div style={{width: 40}}>{/** empty div */}</div>
        {profilePageMode === 'view' &&
          <Avatar
            h={76}
            w={78}
            rounded
            src="https://www.dhanukconnect.com/upload/societies/1/article-banner-image/rmm5LZwJ7X4RokTrkdMLH0SAS0PNv8XI0cw2Ouaf.png"
          />
        }
        {profilePageMode === 'edit' &&
          <ProfilePic onSuccess={() => {}}/>
        }
        <div style={{width: 40}}>
          {profilePageMode === 'view' &&
            <IconComponent
              name="flat_icon_edit"
              className="pdl-ProfilePage-edit-icon"
              onClick={enableProfilePageEditMode}
            />
          }
        </div>
      </div>
      <div style={{ height: 16 }} >{/* just for gap */}</div>
      {profilePageMode === 'view' &&
        <Text
          primary
          fontSize={18}
        >
          K.M.Waliullah
        </Text>
      }
      {profilePageMode === 'edit' &&
        <BlockInput primary value={tempName} onChange={setTempName} placeholder="Name"/>
      }
    </div>
  );
}

function RatingAndTask() {
  return (
    <div className="pdl-RatingAndTask">
      <div className="pdl-ProfilePage-LeftPane-TopCard-rating">
        <IconComponent name="star_fill" className="pdl-ProfilePage-LeftPane-TopCard-starIcon"/>
        <Text b50> 5.0 (20 reviews)</Text>
      </div>
      <div className="pdl-ProfilePage-LeftPane-TopCard-TaskCount">
        <Text b50> 60 overall tasks </Text>
      </div>
    </div>
  );
}

export default function TopCard() {
  return (
    <div className="pdl-ProfilePage-ProfileLeftPane-TopCard">
      <PictureAndName/>
      <RatingAndTask/>
    </div>
  )
}
