import React from 'react';


import './UserInfo.scss';
import Text from '../../../components/Text/Text';
import Avatar from '../../../components/Avatar/Avatar';
import DateAndTime from './DateAndTime/DateAndTime';


export default function UserInfo() {
  return (
    <div className="pdl-CheckoutOrderPage-UserInfo">
      <div className="pdl-header">
        <Text
          primary
          fontSize={18}
          centered
        >
          Review Order
        </Text>
      </div>
      <div className="pdl-body">
        <Avatar
          h={80}
          w={80}
          rounded
          src="https://www.dhanukconnect.com/upload/societies/1/article-banner-image/rmm5LZwJ7X4RokTrkdMLH0SAS0PNv8XI0cw2Ouaf.png"
        />
        <div style={{ marginTop: 8 }}>
          <Text> K.M. Waliullah </Text>
        </div>
        <div style={{ marginTop: 8, marginBottom: 8 , display: 'flex' }}>
          <Text> Project: </Text>
          <div style={{ width: 8 }}></div>
          <Text b50> Furniture Cleaning </Text>
        </div>
        <DateAndTime/>
      </div>
    </div>
  );
}
