import { ValidatorConstraint, ValidatorConstraintInterface, registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

import { propMapHumanReadable } from './utils';

@ValidatorConstraint({ name: 'isNotEmptyAfterTrim', async: true })
class Contstraint implements ValidatorConstraintInterface {
  validate(text: string, _: ValidationArguments): boolean {
    return text?.trim?.().length > 0;
  }

  defaultMessage(args: ValidationArguments) {
    return `${propMapHumanReadable[args.property] || args.property} is empty.`;
  }
}

export function IsNotEmptyAfterTrim(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'isNotEmptyAfterTrim',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: Contstraint,
    });
  };
}
