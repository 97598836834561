import { Contains, IsEmail, IsNotEmpty, IsPhoneNumber, IsPostalCode, Validate } from "class-validator";

import { IsNotEmptyAfterTrim } from "../../ClassValidator/IsNotEmptyAfterTrim";
import { IsValidPostCode } from "../../ClassValidator/IsValidPostCode";
import { MatchesWithProperty } from "../../ClassValidator/MatchesWithProperty";
import { IApiError } from "./ApiError";
import { IUser, UserRole } from "./User";


interface IRegistrationRequest extends IUser {
  confirmPassword?: string,
  countryCode?: string,
};

class RegistrationRequest implements IRegistrationRequest {

  @IsEmail({}, {
    message: 'Invalid email',
  })
  email?: string;

  @IsNotEmptyAfterTrim()
  name?: string;

  @IsNotEmpty()
  password?: string;

  @MatchesWithProperty('password')
  confirmPassword?: string;

  @IsNotEmptyAfterTrim()
  phone?: string;

  @IsNotEmptyAfterTrim()
  @IsValidPostCode()
  postCode?: string;

  @IsNotEmpty()
  userRole?: UserRole;

  @IsNotEmptyAfterTrim()
  username?: string;

  countryCode?: string;

  constructor(x: IRegistrationRequest) {
    this.email = x.email;
    this.name = x.name;
    this.password = x.password;
    this.confirmPassword = x.confirmPassword;
    this.phone = x.phone;
    this.postCode = x.postCode;
    this.userRole = x.userRole;
    this.username = x.username;
    this.countryCode = x.countryCode;
  }
}


interface IRegistrationResponse {
  userId: number,
  hash: string,
  message: string,
  apierror?: IApiError,
};


export type {
  IRegistrationRequest,
  IRegistrationResponse,
}

export {
  RegistrationRequest,
}
