import { ValidatorConstraint, ValidatorConstraintInterface, registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

import { propMapHumanReadable } from './utils';

@ValidatorConstraint({ name: 'IsValidPostCode', async: true })
class Contstraint implements ValidatorConstraintInterface {
  validate(text: string, _: ValidationArguments): boolean {
    const regx = /[A-Z][0-9][A-Z] [A-Z][0-9][A-Z]/g
    return regx.test(text);
  }

  defaultMessage(args: ValidationArguments) {
    return `${propMapHumanReadable[args.property] || args.property} is invalid.`;
  }
}

export function IsValidPostCode(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IsValidPostCode',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: Contstraint,
    });
  };
}
