import React, { useContext } from 'react';


import './ProfileRightPane.scss';
import Services from './Services/Services';
import Gallery from './Gallery/Gallery';
import Certificates from './Certificates/Certificates';
import Reviews from './Reviews/Reviews';
import { UiContext } from '../../../contexts/UiContext';
import UpdatePassword from './UpdatePassword/UpdatePassword';


export default function ProfileRightPane() {
  const { profilePageMode } = useContext(UiContext)!;

  return (
    <div className="pdl-ProfilePage-RightPane">
      <Services/>
      <Gallery/>
      <Certificates/>
      {profilePageMode === 'view' && <Reviews/>}
      {profilePageMode === 'edit' && <UpdatePassword/>}
    </div>
  )
}
