import axios from 'axios';
import { validateOrReject } from 'class-validator';

import { validatorOptions } from '../ClassValidator/utils';
import { ILoginRequest, ILoginResponseCustomized, LoginRequest } from './models/LoginApiModels';
import { IRegistrationRequest, IRegistrationResponse, RegistrationRequest } from './models/RegistrationApiModels';
import { IUser } from './models/User';
import { convertToApiError, generateApiError } from './utils';


const BASE_URL = 'https://api.provisorr.com:8090/provisorr/api';

const api = axios.create({
  baseURL: BASE_URL,
});

export default api;

async function postLogin(loginRequest: ILoginRequest | undefined): Promise<ILoginResponseCustomized> {
  if (!loginRequest) {
    throw generateApiError('Form is empty');
  }

  try {
    await validateOrReject(new LoginRequest(loginRequest), validatorOptions);
    
    const { data } = await api.post('/auth/login', loginRequest);

    /** TODO: After implementing cookie based auth we should remove this line. */
    api.defaults.headers.common.Authorization = data.authToken;

    const loginResponseCustomized = data;
    loginResponseCustomized.user = await getUser(data.userId);

    return loginResponseCustomized;
  } catch(error) {
    console.log(error);
    throw convertToApiError(error);
  }
}

async function getUser(userId: Number): Promise<IUser> {
  try {
    const { data } = await api.get(`/users/${userId}`);
    return data;
  } catch(error) {
    throw convertToApiError(error);
  }
}

async function postRegistration(registrationRequest: IRegistrationRequest | undefined): Promise<IRegistrationResponse> {
  if (!registrationRequest) {
    throw generateApiError('Form is empty');
  }

  try {
    await validateOrReject(new RegistrationRequest(registrationRequest), validatorOptions);

    const { data } = await api.post('/auth/register', registrationRequest);
    return data;
  } catch(error) {
    throw convertToApiError(error);
  }
}


export {
  getUser,
  postLogin,
  postRegistration,
}
