import React, { useContext, useState } from 'react';


import './Customer.scss';
import BlockInput from '../../../components/BlockInput/BlockInput';
import Text from '../../../components/Text/Text';
import { UiContext } from '../../../contexts/UiContext';
import Avatar from '../../../components/Avatar/Avatar';
import ProfilePic from './ProfilePic/ProfilePic';
import IconComponent from '../../../components/IconComponent';
import Button from '../../../components/Button/Button';
import { BackendContext } from '../../../contexts/BackendContext';


export default function Customer() {
  const { profilePageMode } = useContext(UiContext)!;

  return (
    <div className="pdl-ProfilePage-Customer">
      <PictureAndName/>
      <ContactInfo/>
      {profilePageMode === 'edit' && <UpdatePassword/>}
    </div>
  );
}

function PictureAndName() {
  const [ tempName, setTempName ] = useState<string>('');
  const { profilePageMode, enableProfilePageEditMode } = useContext(UiContext)!;
  const { tempToggleUserRole } = useContext(BackendContext)!;

  return (
    <div className="pdl-ProfilePage-Customer-picture-container">
      <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        <div style={{width: 40}}>{/** empty div */}</div>
        {profilePageMode === 'view' &&
          <Avatar
            h={76}
            w={78}
            rounded
            onClick={tempToggleUserRole}
            src="https://www.dhanukconnect.com/upload/societies/1/article-banner-image/rmm5LZwJ7X4RokTrkdMLH0SAS0PNv8XI0cw2Ouaf.png"
          />
        }
        {profilePageMode === 'edit' &&
          <ProfilePic onSuccess={() => {}}/>
        }
        <div style={{width: 40}}>
          {profilePageMode === 'view' &&
            <IconComponent
              name="flat_icon_edit"
              className="pdl-ProfilePage-Customer-edit-icon"
              onClick={enableProfilePageEditMode}
            />
          }
        </div>
      </div>
      <div style={{ height: 16 }} >{/* just for gap */}</div>
      {profilePageMode === 'view' &&
        <Text
          primary
          fontSize={18}
        >
          K.M.Waliullah
        </Text>
      }
      {profilePageMode === 'edit' &&
        <BlockInput primary value={tempName} onChange={setTempName} placeholder="Name"/>
      }
    </div>
  );
}

function InfoItemEditableWrapper({ label, children }: { label: string, children: React.ReactChild }) {
  return (
    <div style={{ display: 'flex', marginBottom: 4 }}>
      <div style={{ marginTop: 4 }}>
        <Text bp> {label}: </Text>
      </div>
      <div style={{ width: 8 }}></div>
      {children}
    </div>
  );
}

function ContactInfoEditable() {
  const [ tempEmail, setTempEmail ] = useState<string>('');
  const [ tempPhone, setTempPhone ] = useState<string>('');
  const [ tempAddress, setTempAddress ] = useState<string>('');
  const [ tempCity, setTempCity ] = useState<string>('');
  const [ tempStateOrProvince, setTempStateOrProvince ] = useState<string>('');
  const [ tempPostalCode, setTempPostalCode ] = useState<string>('');

  return (
    <div className="pdl-ProfilePage-Customer-Contact">
      <Text
        primary
        fontSize={18}
      >
        Contact
      </Text>
      <div style={{ height: 32 }}></div>
      <InfoItemEditableWrapper label={'Email'}>
        <BlockInput primary placeholder="Email" value={tempEmail} onChange={setTempEmail}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'Phone'}>
        <BlockInput primary placeholder="Phone" value={tempPhone} onChange={setTempPhone}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'Address'}>
        <BlockInput primary placeholder="Address" value={tempAddress} onChange={setTempAddress}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'City'}>
        <BlockInput primary placeholder="City" value={tempCity} onChange={setTempCity}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'State/Province'}>
        <BlockInput primary placeholder="State" value={tempStateOrProvince} onChange={setTempStateOrProvince}/>
      </InfoItemEditableWrapper>
      <InfoItemEditableWrapper label={'Postal Code'}>
        <BlockInput primary placeholder="Postal" value={tempPostalCode} onChange={setTempPostalCode}/>
      </InfoItemEditableWrapper>
    </div>
  );
}

function InfoItem({ label, value }: { label: string, value: string }) {
  return (
    <div style={{ display: 'flex' }}>
      <Text bp> {label}: </Text>
      <div style={{ width: 8 }}></div>
      <Text b50> {value} </Text>
    </div>
  );
}

function ContactInfo() {
  const { profilePageMode } = useContext(UiContext)!;
  if (profilePageMode === 'edit') { return <ContactInfoEditable />; }

  return (
    <div className="pdl-ProfilePage-Customer-Contact">
      <Text
        primary
        fontSize={18}
      >
        Contact
      </Text>
      <div style={{ height: 16 }}></div>
      <InfoItem label={'Email'} value={'simple@gmail.com'}/>
      <InfoItem label={'Phone'} value={'+1 604-945-6585'}/>
      <InfoItem label={'Address'} value={'4034  Royal Avenue'}/>
      <InfoItem label={'City'} value={'New Westminster'}/>
      <InfoItem label={'State/Province'} value={'British Columbia'}/>
      <InfoItem label={'Postal Code'} value={'V3L 5H1'}/>
    </div>
  )
}

function UpdatePassword() {
  const [ tempCurrentPassword, setTempCurrentPassword ] = useState<string>('');
  const [ tempNewPassword, setTempNewPassword ] = useState<string>('');
  const [ tempConfirmPassword, setTempConfirmPassword ] = useState<string>('');

  return (
    <div>
      <Text primary fontSize={18}>Update your password</Text>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <div>
          <div style={{ marginTop: 8 }}>
            <Text>Current Password: </Text>
          </div>
          <div style={{ marginTop: 12 }}>
            <Text>New Password: </Text>
          </div>
          <div style={{ marginTop: 14 }}>
            <Text>Confirm Password: </Text>
          </div>
        </div>
        <div style={{ marginLeft: 10, flex: 1 }}>
          <div style={{ marginTop: 4 }}>
            <BlockInput type="password" primary value={tempCurrentPassword} placeholder="Current password" onChange={setTempCurrentPassword}/>
          </div>
          <div style={{ marginTop: 4 }}>
            <BlockInput type="password" primary value={tempNewPassword} placeholder="New password" onChange={setTempNewPassword}/>
          </div>
          <div style={{ marginTop: 4 }}>
            <BlockInput type="password" primary value={tempConfirmPassword} placeholder="Confirm password" onChange={setTempConfirmPassword}/>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row-reverse' }}>
        <Button label="Update"/>
      </div>
    </div>
  )
}
