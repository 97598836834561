import React, { useState } from 'react';


import './Reviews.scss';
import Text from '../../../../components/Text/Text';
import Avatar from '../../../../components/Avatar/Avatar';
import UserRating from '../../../../components/UserRating/UserRating';


export default function Reviews() {
  return (
    <div className="pdl-profile-profileRightPane-Reviews">
      <div style={{ margin: '16px 0px 0px 16px' }}>
        <Text primary fontSize={18}>Ratings and Reviews</Text>
      </div>
      <ReviewItem/>
      <ReviewItem/>
      <ReviewItem/>
      <PaginationPager/>
    </div>
  );
}

type ReviewItemProps = {

};

function ReviewItem({}: ReviewItemProps) {
  return (
    <div className="pdl-ReviewItem">
      <div style={{ display: 'flex' }}>
        <Avatar rounded h={64} w={64} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ_PnmROmOcH9bQsO2pol96gk2FkPYJHyH-w&usqp=CAU"/>
        <div style={{ width: 12 }}>{/** just for space */}</div>
        <div>
          <div style={{ display: 'flex' }}>
            <Text> Jessica Jay</Text>
            <div style={{ width: 12 }}>{/** just for space */}</div>
            <UserRating/>
            <div style={{ width: 182 }}>{/** just for space */}</div>
            <Text b50 fontSize={14}>27 February, 2021</Text>
          </div>
          <div style={{ height: 4 }}>{/** just for space */}</div>
          <Text b50>Very professional. I am highly recommended him.</Text>
        </div>
      </div>
    </div>
  );
}

/** TODO: should be a separate component? */
function PaginationPager() {
  const [ current, setCurrent ] = useState<number>(2);

  const onClickPrevious = () => (current > 1 && setCurrent(current-1));
  const onClickNext = () => (current < 5 && setCurrent(current+1));

  const getBtn = (label: string, onClick: Function) => {
    const imCurrent = current.toString() === label.toString();

    return (
      <div className={`pdl-PaginationPager-btn ${imCurrent && 'pdl-PaginationPager-btn--active'}`} onClick={(e) => onClick()}>
        <Text fontSize={14} white={imCurrent}>{label}</Text>
      </div>
    );
  }

  return (
    <div className="pdl-PaginationPager">
      {getBtn('Previous', onClickPrevious)}
      {getBtn('1', () => setCurrent(1))}
      {getBtn('2', () => setCurrent(2))}
      {getBtn('3', () => setCurrent(3))}
      {getBtn('4', () => setCurrent(4))}
      {getBtn('5', () => setCurrent(5))}
      {getBtn('Next', onClickNext)}
    </div>
  );
}
