import React, { useEffect, useState } from 'react';

import api, {
  postLogin,
  postRegistration,
} from './ApiConfig';
import { IUser, UserRole } from './models/User';
import { IRegistrationRequest, IRegistrationResponse } from './models/RegistrationApiModels';
import { ILoginRequest, ILoginResponseCustomized } from './models/LoginApiModels';
import { IVerifyOtpRequest, IVerifyOtpResponse } from './models/VerifyOtpModels';
import { ISendOtpResponse } from './models/SentOtpApiModels';


type BackendContextType = {
  user: IUser | undefined,
  tempToggleUserRole: Function,

  registration: () => Promise<IRegistrationResponse>,
  registrationRequest: IRegistrationRequest | undefined,
  registrationResponse: IRegistrationResponse | undefined,
  setRegistrationRequest: Function,
  
  login: () => Promise<ILoginResponseCustomized>,
  loginRequest: ILoginRequest | undefined,
  loginResponse: ILoginResponseCustomized | undefined,
  setLoginRequest: Function,

  verifyOtp: Function,
  verifyOtpRequest: IVerifyOtpRequest | undefined,
  setVerifyOtpRequest: Function,

  sendOtp: Function,
}

const BackendContext = React.createContext<BackendContextType | undefined>(undefined);

type BackendContextProviderWrapperProps = { children: JSX.Element };

function BackendContextProviderWrapper({children}: BackendContextProviderWrapperProps) {
  
  const [ user, _setUser ] = useState<IUser | undefined>(undefined);
  
  const [ registrationRequest, setRegistrationRequest ] = useState<IRegistrationRequest | undefined>(undefined);
  const [ registrationResponse, _setRegistrationResponse ] = useState<IRegistrationResponse | undefined>(undefined);
  
  const [ loginRequest, setLoginRequest ] = useState<ILoginRequest | undefined>(undefined);
  const [ loginResponse, _setLoginResponse ] = useState<ILoginResponseCustomized | undefined>(undefined);

  const [ verifyOtpRequest, setVerifyOtpRequest ] = useState<IVerifyOtpRequest | undefined>(undefined);

  useEffect(() => {
    _setUser({
      name: 'John Doe',
      userRole: UserRole.CUSTOMER,
    }); // Demo User

    return () => {
    }
  }, []);

  useEffect(() => {
    console.log(user);

    return () => {
    }
  }, [ user ]);

  const tempToggleUserRole = () =>
    _setUser((currentValue: any) => {
      return {...currentValue, userRole: currentValue.userRole === UserRole.CUSTOMER ? UserRole.SERVICE_PROVIDER : UserRole.CUSTOMER};
    });

  const registration = async (): Promise<IRegistrationResponse> => {
    const customizedRequest: IRegistrationRequest = {
      ...registrationRequest,
      userRole: UserRole.CUSTOMER,
      name: registrationRequest?.username,
      phone: `${registrationRequest?.countryCode ?? "+1"}${registrationRequest?.phone}`,
    };
    try {
      const response = await postRegistration(customizedRequest);
      _setRegistrationResponse(response);
      return response;
    } catch(error) {
      _setRegistrationResponse(error);
      throw error;
    }
  }

  const login = async (): Promise<ILoginResponseCustomized> => {
    try {
      const response = await postLogin(loginRequest);
      _setLoginResponse(response);
      _setUser(response.user);
      return response;
    } catch (error) {
      _setLoginResponse(error);
      throw error;
    }
  }

  const verifyOtp = (onSuccess: (r: IVerifyOtpResponse) => void) => {
    const customizedRequest = {
      ...verifyOtpRequest,
      phone: `${registrationRequest?.countryCode ?? "+1"}${registrationRequest?.phone}`,
      hash: registrationResponse?.hash,
      userId: registrationResponse?.userId,
    };
    api.post('/auth/verify-otp', customizedRequest)
      .then(response => response.data)
      .then(onSuccess)
      .catch((error) => console.log(error));
  }

  const sendOtp = (onSuccess: (r: ISendOtpResponse) => void) => {
    const customizedRequest = {
      phone: `${registrationRequest?.countryCode ?? "+1"}${registrationRequest?.phone}`,
      userId: registrationResponse?.userId,
    };
    api.post('auth/send-otp', customizedRequest)
      .then(response => response.data)
      .then(onSuccess)
      .catch((error) => console.log(error));
  }

  return (
    <BackendContext.Provider value={{
      user,

      registration,
      registrationRequest,
      registrationResponse,
      setRegistrationRequest,

      login,
      loginRequest,
      loginResponse,
      setLoginRequest,

      verifyOtp,
      verifyOtpRequest,
      setVerifyOtpRequest,

      sendOtp,

      tempToggleUserRole,
    }}>
      {children}
    </BackendContext.Provider>
  );
}

export {
  BackendContext,
  BackendContextProviderWrapper,
}

export type {
  BackendContextType,
};
