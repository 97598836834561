import React from 'react';

import {
  Switch,
  Route,
} from 'react-router-dom';

import DesignLibrary from './pages/DesignLibrary/DesignLibrary';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import Messenger from './pages/Messenger/Messenger';
import Earning from './pages/Earning/Earning';
import PromotionalPageWrapper from './pages/PromotionalPageTemporary/PromotioonalPageWrapper';
import Profile from './pages/Profile/Profile';
import OrderHistory from './pages/OrderHistory/OrderHistory';
import CheckoutOrder from './pages/CheckoutOrder/CheckoutOrder';
import BankingInfo from './pages/BankingInfo/BankingInfo';
import PostYourProblem from './pages/PostYourProblem/PostYourProblem';
import Subscription from './pages/Subscription/Subscription';
import AllServices from './pages/AllServices/AllServices';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';

type RouteWithSubRoutesProps = {
  path: string,
  component: any,
  routes?: RouteWithSubRoutesProps[],
};





const AppPaths: RouteWithSubRoutesProps[] = [{
  path: '/design-library/:component',
  component: DesignLibrary,
}, {
  path: '/design-library',
  component: DesignLibrary,
}, {
  path: '/registration',
  component: Registration,
}, {
  path: '/login',
  component: Login,
}, {
  path: '/messenger',
  component: Messenger,
}, {
  path: '/earning',
  component: Earning,
}, {
  path: '/profile',
  component: Profile,
}, {
  path: '/landing-page',
  component: Home,
}, {
  path: '/orders',
  component: OrderHistory,
}, {
  path: '/checkout-order',
  component: CheckoutOrder,
}, {
  path: '/banking-info',
  component: BankingInfo,
}, {
  path: '/post-your-problem',
  component : PostYourProblem,
}, {
  path: '/subscription',
  component: Subscription,
}, {
  path: '/all-services/:component',
  component: AllServices,
}, {
  path: '/all-services',
  component: AllServices,
}, {
  path: '/forget-password',
  component: ForgetPassword,
}, {
  path: '/',
  component: PromotionalPageWrapper,
}];





function RouteWithSubRoutes({ path, component, routes }: RouteWithSubRoutesProps) {
  const Element = component;
  return (
    <Route
      path={path}
      render={props => <Element {...props} routes={routes}/>}
    />
  );
}


type RouterSwitchWrapperProps = {
  switchFor: 'app',
}
export function RouterSwitchWrapper({ switchFor }: RouterSwitchWrapperProps) {

  const routes = ({
    'app': AppPaths,
  }[switchFor]);

  return (
    <Switch>
      {routes.map((route: RouteWithSubRoutesProps, i: number) =>
        <RouteWithSubRoutes key={i} {...route} />
      )}
    </Switch>
  );
}
