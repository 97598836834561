import React from 'react';


import './AdditionalInfo.scss';
import Text from '../../../components/Text/Text';
import IconComponent from '../../../components/IconComponent';



export default function AdditionalInfo() {
  return (
    <div className="pdl-CheckoutOrderPage-AdditionalInfo">
      <div className="pdl-header">
        <div style={{ width: 24 }}></div>
        <Text
          primary
          fontSize={18}
          centered
        >
          Personal Information
        </Text>
        <IconComponent name="flat_icon_edit" className="pdl-editIcon"/>
      </div>
      <div className="pdl-body">
        <div style={{ display: 'flex' }}>
          <div style={{ width: 128 }}>
            <Text>Task Address: </Text>
          </div>
          <div style={{ width: 12 }}>{/** just for space */}</div>
          <div style={{ width: 230 }}>
            <Text b50>
              602-2290 Pembina
              Highway, Winipeg,
              Manitox, R3T 2H5
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
