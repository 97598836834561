import React, { useRef, useLayoutEffect } from 'react';
import IconComponent from '../IconComponent';


import './FileUploaderButton.scss';


type FileUploaderButtonProps = {
  onUploadImage: Function,
  h: number,
  w: number,
};

export default function FileUploaderButton({ onUploadImage, h, w }: FileUploaderButtonProps) {
  const inputFile = useRef<HTMLInputElement>(null);


  useLayoutEffect(() => {

  });

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    if (!file) { return; }

    const fileUrl = URL.createObjectURL(file);
    onUploadImage(fileUrl);
  };

  const onClickUploader = () => inputFile.current?.click();

  return (
    <div>
      <input
        type="file"
        style={{display: 'none'}}
        onChange={handleUpload}
        ref={inputFile}
      />
      <div
        className="pdl-FileUploaderButton"
        style={{ height: h, width: w}}
        onClick={onClickUploader}
      >
        <IconComponent name="plus" className="pdl-Icon"/>
      </div>
    </div>
  );
}
