
enum UserRole {
  CUSTOMER = "CUSTOMER",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
};

interface IUser {
  email?: string,
  name?: string,
  password?: string,
  phone?: string, 
  postCode?: string,
  userRole?: UserRole,
  username?: string,
};

export type {
  IUser,
}

export {
  UserRole,
}
