import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import './DateAndTime.scss';
import IconComponent from '../../../../components/IconComponent';
import Text from '../../../../components/Text/Text';
import Button from '../../../../components/Button/Button';
import IconButton from '../../../../components/IconButton/IconButton';


export default function DateAndTime() {
  const [ modalShown, setModalShown ] = useState<boolean>(false);

  const [ tempSelectedDate, setTempSelectedDate ] = useState<number>(14);
  const [ tempSelectedTime, setTempSelectedTime ] = useState<number>(9);
  const [ firstFiveDays, setFirstFiveDays ] = useState<boolean>(true);
  const tempDateRange: { date: number, day: string }[] = [
    { date: 14, day: 'Today'},
    { date: 15, day: 'Fri'},
    { date: 16, day: 'Sat'},
    { date: 17, day: 'Sun'},
    { date: 18, day: 'Mon'},
    { date: 19, day: 'Tue'},
    { date: 20, day: 'Wed'},
  ];

  return (
    <div className="pdl-CheckoutOrderPage-UserInfo-DateAndTime">
      <div className="pdl-timeContainer">
        <Text>10 am</Text>
      </div>
      <div className="pdl-separator"> </div>
      <div className="pdl-dateContainer">
        <Text b50> March 8, 2021 </Text>
      </div>
      <IconComponent name="flat_icon_edit" className="pdl-editIcon" onClick={() => setModalShown(!modalShown)}/>

      <Modal
        dialogClassName=""
        contentClassName=""
        show={modalShown}
        onHide={() => setModalShown(false)}
      >
        <Modal.Header className="pdl-CheckoutOrderPage-UserInfo-DateAndTime-modalHeader">
          <Text fontWeight={500} centered>Choose your available time</Text>
        </Modal.Header>
        <Modal.Body className="pdl-CheckoutOrderPage-UserInfo-DateAndTime-modalBody">

          <Text fontSize={12}>Choose your preferred date</Text>
          <div style={{ margin: '16px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {!firstFiveDays && <div onClick={(_) => setFirstFiveDays(true)}><IconButton name="arrow_left"/></div>}
            {tempDateRange.filter(({date}) => (firstFiveDays && date < 19) || (!firstFiveDays && date > 15)).map(({ date, day }: { date: number, day: string }) =>
              <div
                className={`pdl-dayItem ${date === tempSelectedDate && 'pdl-dayItem--active'}`}
                key={date}
                onClick={() => setTempSelectedDate(date)}
              >
                <Text
                  fontSize={18}
                  fontWeight={600}
                  primary={date !== tempSelectedDate} white={date === tempSelectedDate}
                >
                  {day}
                </Text>
                <div style={{ height: 2 }}></div>
                <Text
                  fontSize={14}
                  primary={date !== tempSelectedDate}
                  white={date === tempSelectedDate}
                >
                  {date}
                </Text>
              </div>
            )}
            {firstFiveDays && <div onClick={(_) => setFirstFiveDays(false)}><IconButton name="arrow_right"/></div>}
          </div>

          <Text fontSize={12}>Choose your preferred time</Text>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
              <Button label="9:00 am" block outline={tempSelectedTime !== 9} onClick={() => setTempSelectedTime(9)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="10:00 am" block outline={tempSelectedTime !== 10} onClick={() => setTempSelectedTime(10)}/>
              <div style={{ minWidth: 16 }} ></div>
              <Button label="11:00 am" block outline={tempSelectedTime !== 11} onClick={() => setTempSelectedTime(11)}/>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
              <Button label="12:00 am" block outline={tempSelectedTime !== 12} onClick={() => setTempSelectedTime(12)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="1:00 pm" block outline={tempSelectedTime !== 1} onClick={() => setTempSelectedTime(1)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="2:00 pm" block outline={tempSelectedTime !== 2} onClick={() => setTempSelectedTime(2)}/>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
              <Button label="3:00 pm" block outline={tempSelectedTime !== 3} onClick={() => setTempSelectedTime(3)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="4:00 pm" block outline={tempSelectedTime !== 4} onClick={() => setTempSelectedTime(4)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="5:00 pm" block outline={tempSelectedTime !== 5} onClick={() => setTempSelectedTime(5)}/>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
              <Button label="6:00 pm" block outline={tempSelectedTime !== 6} onClick={() => setTempSelectedTime(6)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="7:00 pm" block outline={tempSelectedTime !== 7} onClick={() => setTempSelectedTime(7)}/>
              <div style={{ minWidth: 16 }}></div>
              <Button label="8:00 pm" block outline={tempSelectedTime !== 8} onClick={() => setTempSelectedTime(8)}/>
            </div>
          </div>
          <div style={{ height: 32 }}></div>
          <Button label="Confirm Schedule" block onClick={() => setModalShown(false)}/>
        </Modal.Body>
      </Modal>
    </div>
  )
}
