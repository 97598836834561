import React from 'react';


import './OrderHistory.scss';
import Text from '../../components/Text/Text';
import Container from '../../components/Layout/Container';
import ImageListWithModal from '../../components/ImageListWithModal/ImageListWithModal';


export default function OrderHistory() {
  return (
    <div className="pdl-OrderHistory">
      <Container>
        <div style={{height: 30}}>{/** just for space */}</div>
        <Text fontSize={30} primary> Order History </Text>
        <OrderItem/>
        <OrderItem/>
        <OrderItem/>
      </Container>
    </div>
  );
}

function OrderItem() {
  const getDateComponent = () =>
    <div style={{ display: 'flex' }}>
      <Text> 10 am </Text>
      <div className="pdl-dashline" style={{ margin: '0px 8px' }}></div>
      <Text> 08 March, 2021 </Text>
    </div>

  const getInfoField = (fieldName: string, value: string, options: { primaryValue?: boolean, dateValue?: boolean }) =>
    <div style={{ display: 'flex', marginBottom: 16 }}>
      <div style={{ width: 140, minWidth: 140 }}>
        <Text>{fieldName}</Text>
      </div>
      {!options.dateValue && <Text primary={options.primaryValue}>{value}</Text>}
      {options.dateValue && getDateComponent()}
    </div>

  return (
    <div className="pdl-OrderHistory-OrderItem">
      <div className="pdl-OrderHistory-OrderItem-LeftPane">
        {getInfoField('Order Number:', '#256358', { primaryValue: true })}
        {getInfoField('Project Title:', 'Home Cleaning', {})}
        {getInfoField('Description:', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some...', {})}
        {getInfoField('Date & Time:', '', { dateValue: true })}
      </div>
      <div className="pdl-dashline" style={{ margin: 32 }}></div>
      <div className="pdl-OrderHistory-OrderItem-RightPane">
        <Text>Project Images</Text>
        <div style={{ height: 16 }}>{/** just for space */}</div>
        <ImageListWithModal
          limit={4}
          imageUrls={[
            'https://iso.500px.com/wp-content/uploads/2018/03/500px_blog_spring_pictures.jpg',
            'https://drifterplanet.com/wp-content/uploads/2017/12/Cancun-Private-Beach-Party.jpg',
            'http://localhost:3000/static/media/random-sofa.623a371e.png',
            'https://www.denverpost.com/wp-content/uploads/2018/01/day_in_pictures_010818_001.jpg?w=620',
            'https://www.planetware.com/wpimages/2020/01/india-in-pictures-beautiful-places-to-photograph-taj-mahal.jpg',
            'https://cdn.pixabay.com/photo/2015/09/09/16/05/forest-931706__340.jpg',
          ]}
        />
      </div>
    </div>
  );
}
