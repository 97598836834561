import React from 'react';


import './CheckoutOrder.scss';
import UserInfo from './UserInfo/UserInfo';
import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import OrderSummary from './OrderSummary/OrderSummary';


export default function CheckoutOrder() {
  return (
    <div className="pdl-CheckoutOrderPage">
      <div className="pdl-leftPane">
        <div className="pdl-userInfoContainer">
          <UserInfo/>
        </div>
        <div className="pdl-additionalInfoContainer">
          <AdditionalInfo/>
        </div>
      </div>
      <div className="pdl-rightPane">
        <div className="pdl-summaryContainer">
          <OrderSummary/>
        </div>
      </div>
    </div>
  );
}
