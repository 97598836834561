import React from 'react';


import './ProfileLeftPane.scss';
import TopCard from './TopCard/TopCard';
import BottomCard from './BottomCard/BottomCard';


export default function ProfileLeftPane() {
  return (
    <div className="pdl-ProfilePage-LeftPane">
      <div className="pdl-ProfilePage-LeftPane-TopCard-container">
        <TopCard/>
      </div>
      <div style={{ height: 16 }}> {/** just for gap */} </div>
      <div className="pdl-ProfilePage-LeftPane-BottomCard-container">
        <BottomCard/>
      </div>
    </div>
  )
}
