import React from "react";

import "./AllServices.scss";
import PageFoundation, {
  PageArea,
} from "../../components/Layout/PageFoundation";
import Service from "./Services/Service/Service";
import Text from "../../components/Text/Text";

export default function AllServices() {
  const serviceList = [
    {
      id: 1,
      name: "Home Appliance Installation & Repair",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
    {
      id: 2,
      name: "Home & yard Maintenance",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
    {
      id: 3,
      name: "Cleaning & Pest Control",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
    {
      id: 4,
      name: "Electrical, Plumbing & Wiring",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
    {
      id: 5,
      name: "Tailoring	& Laundry",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
    {
      id: 6,
      name: "Tailoring	& Laundry",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
    {
      id: 7,
      name: "Construction,	Renovation	and	Painting",
      service: [
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
        {
          description: "serviceA, ServiceB, ServiceC, ServiceD",
          imagePath: "",
        },
      ],
    },
  ];

  return (
    <div className="allServices container">
      <div className="header">
        <Text fontSize={32} lineHeight="38px">
          {" "}
          All Services{" "}
        </Text>
      </div>
      <PageFoundation>
        <PageArea size={3}>
          <div className="leftPanel">
            {serviceList.map((service, idx) => {
              return (
                <div>
                  {idx === 0 ? (
                    <div className="serviceList serviceList-active">
                      <Text fontSize={14} lineHeight="21px">
                        {service.name}
                      </Text>
                    </div>
                  ) : (
                    <div className="serviceList">
                      <Text fontSize={14} lineHeight="21px">
                        {service.name}
                      </Text>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </PageArea>
        <PageArea size={9} scrollable scrollbarHide>
          <div className="rightPanel">
            {serviceList.map((service, idx) => (
              <Service
                margine={idx > 0 ? 70 : 0}
                title={service.name}
                card={service.service}
              ></Service>
            ))}
          </div>
        </PageArea>
      </PageFoundation>
    </div>
  );
}
