import React from 'react';
import IconComponent, { SVGIconNames } from '../IconComponent';

import './Button.scss';


type ButtonProps = {
  label: string | React.ReactNode,
  success?: boolean,
  danger?: boolean,
  warning?: boolean,
  wider?: boolean,
  outline?: boolean,
  l?: boolean,
  s?: boolean,
  block?: boolean,
  disabled?: boolean,
  onClick?: Function,
  iconRight?: SVGIconNames,
};

export default function Button({ label, success, danger, warning, outline, wider, s, l, block, disabled, onClick, iconRight }: ButtonProps) {

  let variant = success ? 'success' : danger ? 'danger' : warning ? 'warning' : 'primary';
  if (outline) variant = `outline-${variant}`;

  return (
    <button
      type="button"
      className={`btn btn-${variant} ${s && 'btn-sm'} ${l && 'btn-lg'} ${block && 'btn-block'} pdlButton ${wider && 'pdlButton--wider'} ${disabled && 'pdlButton--disabled'}`}
      onClick={(e) => !disabled && onClick?.()}
    >
      <strong>{label}</strong>
      {iconRight && <IconComponent name={iconRight} className="pdl-Icon"/>}
    </button>
  )
}
