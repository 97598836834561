import React, { useState } from 'react';
import IconComponent, { SVGIconNames } from '../IconComponent';


import './BlockInput.scss';


type BlockInputProps = {
  value: string,
  onChange: Function,
  placeholder?: string,
  primary?: boolean,
  type?: 'text' | 'password' | 'textarea',
  iconRight?: SVGIconNames,
  iconRightOnClick?: Function,
};

function TypeWrapper({ children, primary, textarea }: { children: any, primary?: boolean, textarea?: boolean }) {
  return (
    <div className={`pdl-BlockInput ${primary && 'pdl-BlockInput--primary'} ${textarea && 'pdl-BlockInput--textArea'}`}>
      {children}
    </div>
  );
}

function PasswordInput({
  value,
  onChange,
  placeholder,
  primary,
}: BlockInputProps) {
  const [ show, setShow ] = useState<boolean>(false);

  return (
    <TypeWrapper primary={primary}>
      <input
        type={show ? 'text' : 'password'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <IconComponent name={show ? 'eye' : 'eye_slash'} className="pdl-BlockInput-Icon" onClick={() => setShow(!show)}/>
    </TypeWrapper>
  );
}

function TextInput({
  value,
  onChange,
  placeholder,
  primary,
  iconRight,
  iconRightOnClick,
}: BlockInputProps) {

  return (
    <TypeWrapper primary={primary}>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      {iconRight && <IconComponent name={iconRight} className="pdl-BlockInput-Icon" onClick={iconRightOnClick}/>}
    </TypeWrapper>
  );
}

function TextareaInput({
  value,
  onChange,
  placeholder,
  primary,
  iconRight,
  iconRightOnClick,
}: BlockInputProps) {

  return (
    <TypeWrapper primary={primary} textarea>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      {iconRight && <IconComponent name={iconRight} className="pdl-BlockInput-Icon" onClick={iconRightOnClick}/>}
    </TypeWrapper>
  );
}

export default function BlockInput(props: BlockInputProps) {

  if (props.type === 'password') {
    return <PasswordInput {...props}/>;
  }

  if (props.type === 'textarea') {
    return <TextareaInput {...props}/>;
  }

  return <TextInput {...props}/>;
}
